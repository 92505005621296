import * as React from 'react'

import { createRoot } from 'react-dom/client'
import './index.css'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Main from './main'
import { ChatProvider } from "./components/useChat"
import { CharacterProvider } from "./components/useCharacter"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <Main />,
  },
])

createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <ChatProvider>
      <CharacterProvider>
        <RouterProvider router={router} />
      </CharacterProvider>
    </ChatProvider>
  // </React.StrictMode>
)