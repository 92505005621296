import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

// import { FBXLoader } from "three"

// const animationClips = [
	// "F_Dances_001.fbx",
	// "F_Dances_004.fbx",
	// "F_Dances_005.fbx",
	// "F_Dances_006.fbx",
	// "F_Dances_007.fbx",
	// "F_Standing_Idle_001.fbx",
	// "F_Standing_Idle_Variations_001.fbx",
	// "F_Standing_Idle_Variations_002.fbx",
	// "F_Standing_Idle_Variations_003.fbx",
	// "F_Standing_Idle_Variations_004.fbx",
	// "F_Standing_Idle_Variations_005.fbx",
	// "F_Standing_Idle_Variations_006.fbx",
	// "F_Standing_Idle_Variations_007.fbx",
	// "F_Standing_Idle_Variations_008.fbx",
	// "F_Standing_Idle_Variations_009.fbx",
	// "F_Talking_Variations_001.fbx",
	// "F_Talking_Variations_002.fbx",
	// "F_Talking_Variations_003.fbx",
	// "F_Talking_Variations_004.fbx",
	// "F_Talking_Variations_005.fbx",
	// "F_Talking_Variations_006.fbx",
	// "M_Dances_001.fbx",
	// "M_Dances_002.fbx",
	// "M_Dances_003.fbx",
	// "M_Dances_004.fbx",
	// "M_Dances_005.fbx",
	// "M_Dances_006.fbx",
	// "M_Dances_007.fbx",
	// "M_Dances_008.fbx",
	// "M_Dances_009.fbx",
	// "M_Dances_011.fbx",
	// "M_Standing_Expressions_001.fbx",
	// "M_Standing_Expressions_002.fbx",
	// "M_Standing_Expressions_004.fbx",
	// "M_Standing_Expressions_005.fbx",
	// "M_Standing_Expressions_006.fbx",
	// "M_Standing_Expressions_007.fbx",
	// "M_Standing_Expressions_008.fbx",
	// "M_Standing_Expressions_009.fbx",
	// "M_Standing_Expressions_010.fbx",
	// "M_Standing_Expressions_011.fbx",
	// "M_Standing_Expressions_012.fbx",
	// "M_Standing_Expressions_013.fbx",
	// "M_Standing_Expressions_014.fbx",
	// "M_Standing_Expressions_015.fbx",
	// "M_Standing_Expressions_016.fbx",
	// "M_Standing_Expressions_017.fbx",
	// "M_Standing_Expressions_018.fbx",
	// "M_Standing_Idle_001.fbx",
	// "M_Standing_Idle_002.fbx",
	// "M_Standing_Idle_Variations_001.fbx",
	// "M_Standing_Idle_Variations_002.fbx",
	// "M_Standing_Idle_Variations_003.fbx",
	// "M_Standing_Idle_Variations_004.fbx",
	// "M_Standing_Idle_Variations_005.fbx",
	// "M_Standing_Idle_Variations_006.fbx",
	// "M_Standing_Idle_Variations_007.fbx",
	// "M_Standing_Idle_Variations_008.fbx",
	// "M_Standing_Idle_Variations_009.fbx",
	// "M_Standing_Idle_Variations_010.fbx",
	// "M_Talking_Variations_001.fbx",
	// "M_Talking_Variations_002.fbx",
	// "M_Talking_Variations_003.fbx",
	// "M_Talking_Variations_004.fbx",
	// "M_Talking_Variations_005.fbx",
	// "M_Talking_Variations_006.fbx",
	// "M_Talking_Variations_007.fbx",
	// "M_Talking_Variations_008.fbx",
	// "M_Talking_Variations_009.fbx",
	// "M_Talking_Variations_010.fbx",
// ]

// const animationClips = {
// 	"weight_shift": "/animations/mixamo/weight_shift.glb",
// 	"look_around": "/animations/mixamo/look_around.glb",
// 	"sad_idle": "/animations/mixamo/sad_idle.glb",
// 	"offensive_idle": "/animations/mixamo/offensive_idle.glb",
// 	// "victory_idle": "victory_idle.glb",
// 	// "happy_idle": "happy_idle.glb",
// }

const animationClips = {
	"idle": "/animations/mixamo/idle.fbx",
	"weight_shift": "/animations/mixamo/weight_shift.fbx",
	"breathing_idle": "/animations/mixamo/breathing_idle.fbx",
	"dwarf_idle": "/animations/mixamo/dwarf_idle.fbx",
	"dwarf_idle2": "/animations/mixamo/dwarf_idle2.fbx",
	"happy_idle": "/animations/mixamo/happy_idle.fbx",
	"standing_w_briefcase_idle": "/animations/mixamo/standing_w_briefcase_idle.fbx",
	"happy": "/animations/mixamo/happy.fbx",

	"drunk_idle": "/animations/mixamo/drunk_idle.fbx",
	"fighting_idle": "/animations/mixamo/fighting_idle.fbx",
	"offensive_idle": "/animations/mixamo/offensive_idle.fbx",
	"sad_idle": "/animations/mixamo/sad_idle.fbx",
	"unarmed": "/animations/mixamo/unarmed_idle.fbx",
	"alert": "/animations/mixamo/holding_idle.fbx",

	"yelling": "/animations/mixamo/yelling.fbx",
	"standing_arguing": "/animations/mixamo/standing_arguing.fbx",

	"talking_1": "/animations/mixamo/talking_1.fbx",
	"talking_2": "/animations/mixamo/talking_2.fbx",
	"talking_3": "/animations/mixamo/talking_3.fbx",
	"angry": "/animations/mixamo/angry.fbx", //kind of a good talk cycle that isnt' necessarily mad kinda more cool

	"hanging": "/animations/mixamo/hanging_idle.fbx",
	"falling": "/animations/mixamo/falling_to_landing.fbx"
}

// todo this doesnt work becasue of some weird scaling or something from fbx
const loadFBX = ([name, path]) => {
    return new Promise((resolve, reject) => {
        const loader = new FBXLoader()
        loader.load(`${path}`,
            (fbx) => {
            	// console.log("fbx", fbx)
            	if (fbx.animations.length === 1) {
	            	fbx.animations[0].name = name.toLowerCase()
            	} else {
            		fbx.animations.forEach(animation => {
            			animation.name = animation.name.toLowerCase()
            		})
            	}

                resolve(fbx.animations)
            },
            undefined,
            (error) => reject(error)
        )
    })
}
const loadGLTF = ([name, path]) => {
    return new Promise((resolve, reject) => {
        const loader = new GLTFLoader()
        // Initialize Draco loader
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco/') // Path to Draco decoder
        loader.setDRACOLoader(dracoLoader)

        loader.load(`${path}`,
            (gltf) => {
            	// if there's just one rename it
            	if (gltf.animations.length === 1) {
	            	gltf.animations[0].name = name.toLowerCase()
            	} else {
            		gltf.animations.forEach(animation => {
            			animation.name = animation.name.toLowerCase()
            		})
            	}

                resolve(gltf.animations)
            },
            undefined,
            (error) => reject(error)
        )
    })
}

export const getAnimationClips = async (type="glb") => {
    try {
        const animations = await Promise.all(
            Object.entries(animationClips).map(keyVal => type === "fbx" ? loadFBX(keyVal) : loadGLTF(keyVal))
            // loadGLTF(["anim", "/animations/animations.glb"])]
        )
        // console.log("animations", animations.flat())
        return animations.flat()
    } catch (error) {
        console.error('Error loading animations:', error)
        return []
    }
}