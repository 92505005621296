import { useEffect, forwardRef } from 'react'
import { useSelectionContext, useObjectsContext } from "./SceneContext"
import { getFileWithoutExtension, getFileExtension} from './Utils'
import { toast } from 'sonner'
import { getFigmaNodes } from './Figma'

import { useNavigate } from 'react-router-dom'

const InputHelpers = forwardRef(
  (
    {
      children,
      createObject,
      ...rest
    },
    ref
  ) => {

    // useEffect(() => {
    //   // see: https://github.com/mrdoob/three.js/blob/12204a5718c1872e95e402b49d4a45fdf1b55f0e/editor/index.html#L191-L209
    //   const handleDragOver = (e) => {
    //     e.preventDefault()

    //     e.dataTransfer.dropEffect = 'copy'
    //     // console.log("drag over", e)
    //   }

    //   const handleDrop = (e) => {
    //     e.preventDefault()
    //     if ( e.dataTransfer.types[ 0 ] === 'text/plain' ) return; // Outliner drop
    //     // console.log("drop", e.dataTransfer?.files[0])
    //     const files = e.dataTransfer?.files

    //     if (files) {
    //       handleFileInputChange(files, createObject)
    //     }

    //     // if ( e.dataTransfer.items ) {
    //     //   // DataTransferItemList supports folders
    //     //   // editor.loader.loadItemList( e.dataTransfer.items )
    //     // } else {
    //     //   // editor.loader.loadFiles( e.dataTransfer.files )
    //     // }
    //   }

    //   const handlePaste = async (e) => {
    //     e.preventDefault()
    //     // Get the clipboard contents as plain text
    //     const clipboardData = e.clipboardData || window.clipboardData
    //     const pastedText = clipboardData.getData("text/plain")
    //     console.log("copy paste", pastedText)
    //     if (pastedText.toLowerCase().includes("figma.com")) {
    //       const toastStart = toast(`pasting figma...`)

    //       const {data, page, node} = await getFigmaNodes({url: pastedText})

    //       data.forEach(val => {
    //         createObject(val)
    //       })

    //       window.history.pushState( {} , 'bar', `/?page=${page}&node=${node}` )

    //       toast.dismiss(toastStart)
    //       toast.success(`pasted!`)
    //     } else {
    //       toast(`pasted content not supported`)
    //     }
    //   }

    //   document.addEventListener('dragover', handleDragOver)
    //   document.addEventListener('drop', handleDrop)
    //   document.addEventListener("paste", handlePaste)

    //   return () => {
    //     document.removeEventListener('dragover', handleDragOver)
    //     document.removeEventListener('drop', handleDrop)
    //     document.removeEventListener('paste', handlePaste)
    //   }
    // }, [])


    return (
      <div
        ref={ref}
        {...rest}
      >
        <input
          id={"inputTextHelper"}
          className="pointer-events-none text-black opacity-0 absolute"
          type="text"
          autoComplete="off"
        />

        <input
          id={"inputFileHelper"}
          className="pointer-events-none text-black opacity-0 absolute"
          type="file"
          accept="image/*, .glb, .gltf"
        />
      </div>
    )
  }
)

export async function importFigma({page, node, createObject}) {
  console.log("get figma", page, node)
  if (page && node) {
    const toastStart = toast(`loading figma...`)

    console.log("import url", page, node)
    const {data} = await getFigmaNodes({page, node})
    data.forEach(val => {
      createObject(val)
    })
    toast.dismiss(toastStart)
  } else {
    toast(`content not supported`)
  }
}

// see: https://discourse.threejs.org/t/drag-and-drop-glb-file-using-html/37242
function mapFileType(file) {
  const extension = getFileExtension(file.name)

  if (file.type && file.type.includes("image")) {
    return "image"
  } else if (extension === ".glb" || extension === ".gltf") {
    return "gltf"
  }
}

function handleFileInputChange(files, createObject) {
    // console.log("file", e.files, createObject)
    const file = files[0]

    if (file) {
      // todo need to unload this
      const imageUrl = URL.createObjectURL(file)

      const type = mapFileType(file)

      createObject({
        name: getFileWithoutExtension(file.name),
        type: type,
        src: imageUrl,
      })
    }

    // unmount listener
    const inputHelper = document.getElementById("inputFileHelper")
    inputHelper.removeEventListener("change", handleFileInputChange)
}

export function getFileInput(e, createObject) {
  const inputHelper = document.getElementById("inputFileHelper")
  // createObject({type: "GLTF", src:"/GLTF/DamagedHelmet.glb"}
  if (inputHelper) {
    inputHelper.click()

    inputHelper.addEventListener("change", (e) => handleFileInputChange(inputHelper.files, createObject))
  }
}

export function resetInput() {
  const inputHelper = document.getElementById("inputTextHelper")

  if (inputHelper) {
    inputHelper["value"] = ""
    inputHelper.blur()
    // inputHelper.disabled = true

    const new_element = inputHelper.cloneNode(true)
    inputHelper.parentNode.replaceChild(new_element, inputHelper)
  }
}

export default InputHelpers