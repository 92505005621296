/*
see: https://github.com/wass08/r3f-virtual-girlfriend-frontend/blob/main/src/components/Avatar.jsx

Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 public/models/64f1a714fe61576b46f27ca2.glb -o src/components/Avatar.jsx -k -r public
*/

import { useAnimations, useFBX, useGLTF, useCursor, Box } from "@react-three/drei";
import { useFrame, mesh, boxGeometry } from "@react-three/fiber";
import { button, useControls } from "leva";
import React, { Suspense, useEffect, useRef, useState, useCallback } from "react";
import { runBlendshapesDemo, registerCallback, audioBlendshapes, isTalking } from '../apis/talkingHead'
import { useCharacter } from './useCharacter'
import { randomChoice } from './Utils'
import { getAnimationClips } from './AnimationClips'

import { LoopOnce, LoopRepeat, MathUtils, DoubleSide } from "three"

const facialExpressions = {
  default: {},
  smile: {
    browInnerUp: 0.17,
    eyeSquintLeft: 0.4,
    eyeSquintRight: 0.44,
    noseSneerLeft: 0.1700000727403593,
    noseSneerRight: 0.14000002836874015,
    mouthPressLeft: 0.61,
    mouthPressRight: 0.41000000000000003,
  },
  funnyFace: {
    jawLeft: 0.63,
    mouthPucker: 0.53,
    noseSneerLeft: 1,
    noseSneerRight: 0.39,
    mouthLeft: 1,
    eyeLookUpLeft: 1,
    eyeLookUpRight: 1,
    cheekPuff: 0.9999924982764238,
    mouthDimpleLeft: 0.414743888682652,
    mouthRollLower: 0.32,
    mouthSmileLeft: 0.35499733688813034,
    mouthSmileRight: 0.35499733688813034,
  },
  sad: {
    mouthFrownLeft: 1,
    mouthFrownRight: 1,
    mouthShrugLower: 0.78341,
    browInnerUp: 0.452,
    eyeSquintLeft: 0.72,
    eyeSquintRight: 0.75,
    eyeLookDownLeft: 0.5,
    eyeLookDownRight: 0.5,
    jawForward: 1,
  },
  surprised: {
    eyeWideLeft: 0.5,
    eyeWideRight: 0.5,
    jawOpen: 0.351,
    mouthFunnel: 1,
    browInnerUp: 1,
  },
  angry: {
    browDownLeft: 1,
    browDownRight: 1,
    eyeSquintLeft: 1,
    eyeSquintRight: 1,
    jawForward: 1,
    jawLeft: 1,
    mouthShrugLower: 1,
    noseSneerLeft: 1,
    noseSneerRight: 0.42,
    eyeLookDownLeft: 0.16,
    eyeLookDownRight: 0.16,
    cheekSquintLeft: 1,
    cheekSquintRight: 1,
    mouthClose: 0.23,
    mouthFunnel: 0.63,
    mouthDimpleRight: 1,
  },
  crazy: {
    browInnerUp: 0.9,
    jawForward: 1,
    noseSneerLeft: 0.5700000000000001,
    noseSneerRight: 0.51,
    eyeLookDownLeft: 0.39435766259644545,
    eyeLookUpRight: 0.4039761421719682,
    eyeLookInLeft: 0.9618479575523053,
    eyeLookInRight: 0.9618479575523053,
    jawOpen: 0.9618479575523053,
    mouthDimpleLeft: 0.9618479575523053,
    mouthDimpleRight: 0.9618479575523053,
    mouthStretchLeft: 0.27893590769016857,
    mouthStretchRight: 0.2885543872656917,
    mouthSmileLeft: 0.5578718153803371,
    mouthSmileRight: 0.38473918302092225,
    tongueOut: 0.9618479575523053,
  },
};

// chatgpt remapped the dog mappings to arkits mappings
const dogMapping = {
  Jaw_L: "jawLeft",
  Jaw_R: "jawRight",
  Mouth_Open: "jawOpen",
  Mouth_L: "mouthLeft",
  Mouth_R: "mouthRight",
  Lips_Down: "mouthFrownLeft", // or another related mouth blendshape
  Lips_Up: "mouthSmileLeft",
  Brow_Lower_L: "browDownLeft",
  Brow_Raise_L: "browInnerUp",
  Brow_Lower_R: "browDownRight",
  Brow_Raise_R: "browOuterUpRight",
  Eye_Close_L: "eyeBlinkLeft",
  Eye_Open_L: "eyeWideLeft",
  Eye_Close_R: "eyeBlinkRight",
  Eye_Open_R: "eyeWideRight",
  Kiss: "mouthPucker",
  Smile_L: "mouthSmileLeft",
  Smile_R: "mouthSmileRight",
  Lips_Corner_Lower_L: "mouthFrownLeft",
  Lips_Corner_Lower_R: "mouthFrownRight",
  Upper_Lip_Raise_L: "mouthUpperUpLeft",
  Upper_Lip_Raise_R: "mouthUpperUpRight",
  Eye_Move_Up_L: "eyeLookUpLeft",
  Eye_Move_Down_L: "eyeLookDownLeft",
  Eye_Move_In_L: "eyeLookInLeft",
  Eye_Move_Out_L: "eyeLookOutLeft",
  Eye_Move_Up_R: "eyeLookUpRight",
  Eye_Move_Down_R: "eyeLookDownRight",
  Eye_Move_In_R: "eyeLookInRight",
  Eye_Move_Out_R: "eyeLookOutRight"
}

let setupMode = false

const Character = React.forwardRef((props, ref) => {
  const { model } = useCharacter()
  const group = useRef()
  const [animations, setAnimations] = useState([])

  // Merge the forwarded ref with our local group ref
  useEffect(() => {
    if (ref) {
      ref.current = group.current
    }
  }, [ref])

  useEffect(() => {
    // init talking head blendshape demo
    runBlendshapesDemo(false)

    // loading my own animations eventually
    const loadAnimations = async () => {
      const newAnimations = await getAnimationClips("fbx")
      // console.log("new", newAnimations)
      setAnimations(newAnimations)
    }

    loadAnimations()
  }, [])

  const { nodes, materials, scene } = useGLTF(model)
  // const { animations } = useGLTF("/models/animations.glb")



  useEffect(() => {
    // set frustrum culled to false so camera doesnt clip
    Object.values(nodes).map(object => {
      if (object.isMesh) {
        // console.log("object mesh", object.frustrumCulled, object)
        object.frustumCulled = false
      }
    })
  }, [nodes])

  const updateBlendshapes = useCallback((blendshapes) => {

    if (!nodes) return

    // console.log("blendshapes", blendshapes)
    // console.log("nodes", nodes, nodes.morphTargetDictionary)

    for (const name in blendshapes) {
      let value = blendshapes[name]
      if (!Object.keys(nodes.morphTargetDictionary).includes(name)) {
        continue
      }
      const idx = nodes.morphTargetDictionary[name]
      nodes.morphTargetInfluences[idx] = value
    }

  }, [model])


  const lerpMorphTarget = useCallback((target, value, speed=0.1, type="normal") => {
    scene.traverse((child) => {
      if (child.isSkinnedMesh && child.morphTargetDictionary) {
        // const index = child.morphTargetDictionary[target]

        const index = type === "dog" ? child.morphTargetDictionary[dogMapping[target]] : child.morphTargetDictionary[target]


        if (index === undefined || child.morphTargetInfluences[index] === undefined) {
          // console.log("unde", target)
          return
        }

        // dup map the eyes on dog mode
        if (type === "dog") {
          // only do it on the Right ones when found
          if (target === "Eye_Close_L" || target === "Eye_Open_L" || target === "Eye_Close_R" || target === "Eye_Open_R") {
            const factor = .75
            const speedFactor = .5

            if (target === "Eye_Close_R") {
              child.morphTargetInfluences[child.morphTargetDictionary[dogMapping["Eye_Close_L"]]] = MathUtils.lerp(
                child.morphTargetInfluences[index],
                value*factor,
                speed*speedFactor
              )
              child.morphTargetInfluences[child.morphTargetDictionary[dogMapping["Eye_Close_R"]]] = MathUtils.lerp(
                child.morphTargetInfluences[index],
                value*factor,
                speed*speedFactor
              )
              return
            }

            if (target === "Eye_Open_R") {
              child.morphTargetInfluences[child.morphTargetDictionary[dogMapping["Eye_Open_L"]]] = MathUtils.lerp(
                child.morphTargetInfluences[index],
                value*factor,
                speed*speedFactor
              )
              child.morphTargetInfluences[child.morphTargetDictionary[dogMapping["Eye_Open_R"]]] = MathUtils.lerp(
                child.morphTargetInfluences[index],
                value*factor,
                speed*speedFactor
              )
              return
            }
          }
        }

        // uncomment to set without lerp
        // child.morphTargetInfluences[index] = value

        child.morphTargetInfluences[index] = MathUtils.lerp(
          child.morphTargetInfluences[index],
          value,
          speed
        )



        // if (!setupMode) {
        //   try {
        //     set({
        //       [target]: value,
        //     });
        //   } catch (e) {}
        // }

      }
    })
  }, [scene])

  const [blink, setBlink] = useState(false);
  const [audio, setAudio] = useState();
  const [winkLeft, setWinkLeft] = useState(false);
  const [winkRight, setWinkRight] = useState(false);
  const [facialExpression, setFacialExpression] = useState("");

  useFrame(() => {
    // rpm reports a couple different formats for where their morphs are located, this handles it loosely
    const morphDictionary = nodes?.Wolf3D_Avatar?.morphTargetDictionary || nodes?.EyeLeft?.morphTargetDictionary
    // console.log("nodes", nodes?.Wolf3D_Avatar?.morphTargetDictionary, nodes?.EyeLeft?.morphTargetDictionary)
    if (!morphDictionary) return

    !setupMode &&
      Object.keys(morphDictionary).forEach((key) => {
        const mapping = facialExpressions[facialExpression];
        // console.log("nodes", key)
        if (key === "eyeBlinkLeft" || key === "eyeBlinkRight") {
          return; // eyes wink/blink are handled separately
        }
        if (mapping && mapping[key]) {
          lerpMorphTarget(key, mapping[key], 0.1);
        } else {
          lerpMorphTarget(key, 0, 0.1);
        }
      });

    lerpMorphTarget("eyeBlinkLeft", blink || winkLeft ? 1 : 0, 0.5);
    lerpMorphTarget("eyeBlinkRight", blink || winkRight ? 1 : 0, 0.5);

    // LIPSYNC
    if (setupMode) {
      return;
    }

    const appliedMorphTargets = []

    if (audioBlendshapes && isTalking) {
      // console.log("setup mode", setupMode)
      const blendShapesMapping = audioBlendshapes?.getBlendshapes();

      Object.entries(blendShapesMapping).forEach(([key, val]) => {
        // console.log(audioBlendshapes.blendshapes.length)
        lerpMorphTarget(key, val, .8, "dog")
        // console.log("key", key, val)
      })

    }

  })


  useEffect(() => {
    let blinkTimeout;
    const nextBlink = () => {
      blinkTimeout = setTimeout(() => {
        setBlink(true);
        setTimeout(() => {
          setBlink(false);
          nextBlink();
        }, 200);
      }, MathUtils.randInt(1000, 5000));
    };
    nextBlink();
    return () => clearTimeout(blinkTimeout);
  }, [model]);


  return (
    <group {...props} dispose={null} ref={group}>
      <Suspense fallback={null}>
        <primitive
          key={`model_${model}`}
          object={scene}
        >
        </primitive>
        {animations && animations.length > 0 &&
          <AnimationController key={model} animations={animations} lerpMorphTarget={lerpMorphTarget} nodes={nodes} group={group} />
        }
      </Suspense>
    </group>
  )
})

// to add animations see: https://www.youtube.com/watch?v=EzzcEL_1o9o
function AnimationController({animations, group, lerpMorphTarget, nodes}) {
  const { actions, mixer } = useAnimations(animations, group)
  const [animation, setAnimation] = useState("standing_w_briefcase_idle")
  const { emotion, isEditing, isFalling } = useCharacter()

  useEffect(() => {
    if (isEditing) {
      setAnimation("hanging")
    } else {
      setAnimation("falling")
    }
  }, [isEditing])

  useEffect(() => {
    // catch on init, if its editing dont continue
    if (isEditing) {
      setAnimation("hanging")
      return
    }

    // negative dudes
    if (["pessimistic"].includes(emotion)) {
      setAnimation(randomChoice(["sad_idle"]))
    } else if (["tired"].includes(emotion)) {
      setAnimation(randomChoice(["drunk_idle"]))
    } else if (["insecure"].includes(emotion)) {
      setAnimation(randomChoice(["sad_idle"]))
    } else if (["angry"].includes(emotion)) {
      setAnimation(randomChoice(["angry"]))
    } else if (["serious"].includes(emotion)) {
      setAnimation(randomChoice(["offensive_idle"]))

    // positive dudes
    } else if (["confident"].includes(emotion)) {
      setAnimation(randomChoice(["offensive_idle"]))
    } else if (["optimistic"].includes(emotion)) {
      setAnimation(randomChoice(["happy"]))
    } else if (["funny"].includes(emotion)) {
      setAnimation(randomChoice(["happy_idle"]))
    } else if (["alert"].includes(emotion)) {
      setAnimation(randomChoice(["alert"]))
    } else {
      setAnimation(randomChoice(["idle", "weight_shift", "breathing_idle", "dwarf_idle", "dwarf_idle2", "standing_w_briefcase_idle", ]))
    }
  }, [emotion])

  useEffect(() => {
    // catch on init, if its editing dont continue
    if (isEditing) {
      setAnimation("hanging")
      return
    }

    if (isTalking) {
      if (emotion === "angry") {
        setAnimation(randomChoice(["standing_arguing", "yelling", "angry"]))
      } else {
        setAnimation(randomChoice(["talking_1", "talking_2", "talking_3", "angry"]))
      }
    } else {
      setAnimation(randomChoice(["idle", "weight_shift", "breathing_idle", "dwarf_idle", "dwarf_idle2", "standing_w_briefcase_idle"]))
    }
  }, [isTalking])

  useEffect(() => {
    // console.log("actions", animations[0].name, actions[animation])
    if (actions[animation]) {
      actions[animation].clampWhenFinished = true // set this so it doesn't go to weird t-pose when fading out
      actions[animation]
        .reset()
        .fadeIn(mixer.stats.actions.inUse === 0 ? 0 : 0.5)
        .play()
        .setLoop(animation === "falling" ? LoopOnce : LoopRepeat)
    }
    return () => {
      if (actions[animation]) {
        actions[animation].fadeOut(0.5)
      }
    }
  }, [animation])

  useEffect(() => {
    // if falling is finished, go back to idle
    const handleFinished = (e) => {
      if (e.action.getClip().name === "falling" && !isEditing) {
        // reset to idle
        setAnimation(randomChoice(["idle", "weight_shift", "breathing_idle", "dwarf_idle", "dwarf_idle2", "standing_w_briefcase_idle"]))
        mixer.removeEventListener('finished', handleFinished)
      }
    }

    mixer.addEventListener('finished', handleFinished)

    // Clean up the event listener when component unmounts
    return () => mixer.removeEventListener('finished', handleFinished)
  }, [mixer, isEditing])

  // useControls("FacialExpressions", {
  //   // chat: button(() => chat()),
  //   // winkLeft: button(() => {
  //   //   setWinkLeft(true);
  //   //   setTimeout(() => setWinkLeft(false), 300);
  //   // }),
  //   // winkRight: button(() => {
  //   //   setWinkRight(true);
  //   //   setTimeout(() => setWinkRight(false), 300);
  //   // }),
  //   animation: {
  //     value: animation,
  //     options: animations.map((a) => a.name),
  //     onChange: (value) => setAnimation(value),
  //   },
  //   // facialExpression: {
  //   //   options: Object.keys(facialExpressions),
  //   //   onChange: (value) => setFacialExpression(value),
  //   // },
  //   enableSetupMode: button(() => {
  //     setupMode = true;
  //   }),
  //   disableSetupMode: button(() => {
  //     setupMode = false;
  //   }),
  //   logMorphTargetValues: button(() => {
  //     const emotionValues = {};
  //     Object.keys(nodes.EyeLeft.morphTargetDictionary).forEach((key) => {
  //       if (key === "eyeBlinkLeft" || key === "eyeBlinkRight") {
  //         return; // eyes wink/blink are handled separately
  //       }
  //       const value =
  //         nodes.EyeLeft.morphTargetInfluences[
  //           nodes.EyeLeft.morphTargetDictionary[key]
  //         ];
  //       if (value > 0.01) {
  //         emotionValues[key] = value;
  //       }
  //     });
  //     console.log(JSON.stringify(emotionValues, null, 2));
  //   }),
  // });

  // const [, set] = useControls("MorphTarget", () =>
  //   Object.assign(
  //     {},
  //     ...Object.keys(nodes.EyeLeft.morphTargetDictionary).map((key) => {
  //       return {
  //         [key]: {
  //           label: key,
  //           value: 0,
  //           min: nodes.EyeLeft.morphTargetInfluences[
  //             nodes.EyeLeft.morphTargetDictionary[key]
  //           ],
  //           max: 1,
  //           onChange: (val) => {
  //             if (setupMode) {
  //               lerpMorphTarget(key, val, 1);
  //             }
  //           },
  //         },
  //       };
  //     })
  //   )
  // )

  return (<></>)
}

export default Character