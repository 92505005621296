import { useRef, createContext, useCallback, useContext, useEffect, useState } from "react"

import {randomChoice} from './Utils'

const CharacterContext = createContext()

// girls
export const models = {
  "redAsian": "6498dd31e5db1e4d4f10ac7b",
  "newBalanceGirl": "61f886f18826d06f9c8b985f",
  "blond": "61f87a058826d06f9c8a1780",
  "blondBlackJacket": "61f9afe38826d06f9cadf6b0",
  "blondGlasses": "648a2e4dc91663ff974919e8",
  "asianPonyTails": "638905eba3a085619eaf9c02",
  "blackGirl": "638905a559d13f203a31be11",
  // "punkGirl": "6386a03e226673df8a0dea04"
  "racerGirl": "6389059c59d13f203a31bdfd"
}

export function getModelURL(id) {
  // const modelURL = `https://models.readyplayer.me/${id}.glb?morphTargets=ARKit&textureAtlas=512&useDracoMeshCompression=true&useMeshOptCompression=true`
  const modelURL = `https://models.readyplayer.me/${id}.glb?morphTargets=ARKit&useDracoMeshCompression=true&useMeshOptCompression=true`
  return modelURL
}

export function getImageURL(id) {
  const imageURL = `https://models.readyplayer.me/${id}.png?size=256`
  return imageURL
}

// // guys
// const models = {
//   "indian": "6243702fcc9780a0690cc4f2",
//   "asianSuitDude": "62436cf3cc9780a0690c6818",
// }


export const CharacterProvider = ({ children }) => {
  // console.log("rando", randomChoice(Object.values(models)))
  const randomModel = randomChoice(Object.values(models))
  const modelURL = getModelURL(randomModel)

  const [model, setModel] = useState(modelURL)
  const [voice, setVoice] = useState(null)
  const [backstory, setBackstory] = useState(null)
  const [emotion, setEmotion] = useState(null)
  const [position, setPosition] = useState([0, 0, 0])
  const [isEditing, setIsEditing] = useState(false)
  const [isExiting, setIsExiting] = useState(false)
  const [isFalling, setIsFalling] = useState(false)

  useEffect(() => {
    if (isEditing) {
      setIsExiting(true)
    }
  }, [isEditing]);


  const updateEmotion = useCallback((array) => {
    // console.log('slider', array)

    const mapping = {
      0: ["pessimistic", "neutral", "optimistic"],
      1: ["serious", "neutral", "funny"],
      2: ["angry", "neutral", "calm"],
      3: ["tired", "neutral", "alert"],
      4: ["insecure", "neutral", "confident"],
    }

    const dominantEmotions = array.map((value, index) => {
      const modValue = value - 50
      if (modValue < -20) return mapping[index][0];      // negative emotion
      if (modValue > 20) return mapping[index][2];      // positive emotion
      return mapping[index][1];                      // neutral
    })

    // get the highest value and map back to the dominant emotion
    const absDistances = array.map(value => Math.abs(value - 50))
    const maxAbsIndex = absDistances.indexOf(Math.max(...absDistances))
    const dominantEmotion = dominantEmotions[maxAbsIndex]

    // console.log('Dominant emotion:', dominantEmotion)
    setEmotion(dominantEmotion)
  }, [])


  const updateModel = useCallback((modelID) => {
    setModel(getModelURL(modelID))
  }, [])

  return (
    <CharacterContext.Provider
      value={{
        model,
        setModel,
        updateModel,
        voice,
        setVoice,
        emotion,
        setEmotion,
        updateEmotion,
        backstory,
        setBackstory,
        position,
        setPosition,
        isEditing,
        setIsEditing,
        isExiting,
        setIsExiting,
        isFalling,
        setIsFalling
      }}
    >
      {children}
    </CharacterContext.Provider>
  )
}

export const useCharacter = () => {
  const context = useContext(CharacterContext)

  if (!context) {
    throw new Error("CharacterContext must be used within a ChatProvider");
  }

  return context
}
