// handles most of all objects that are created
// note that we create a new store for each object and pass that to
// object state so just one leva control shows up on select. see: https://codesandbox.io/p/sandbox/leva-ui-o8biid?file=%2Fsrc%2Findex.jsx%3A14%2C1

import {useRef, useLayoutEffect, useEffect, useState, useCallback, useMemo} from 'react'
import { Box3, TextureLoader, DoubleSide, Vector3, Matrix4, BoxHelper, ClampToEdgeWrapping, RepeatWrapping, ImageLoader, SRGBColorSpace, PointLightHelper } from "three"
import { useControls, useCreateStore, folder, button } from 'leva'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader'
import { RGBELoader } from 'three-stdlib'


// const { Row, Label, String } = Components

import { useFrame, applyProps, useLoader } from '@react-three/fiber'
import {
  Html,
  Caustics,
  Box,
  Cylinder,
  useGLTF,
  RoundedBox,
  Outlines,
  Center,
  Text,
  Edges,
  Text3D,
  Instance,
  Instances,
  Environment,
  useEnvironment,
  Lightformer,
  ContactShadows,
  DragControls,
  useAspect,
  PivotControls,
  useCursor,
  MeshTransmissionMaterial,
  MeshRefractionMaterial,
  Helper,
  useTexture,
} from '@react-three/drei'

import { motion } from "framer-motion-3d"

import { useCameraContext, useSelectionContext, useDraggingSelectionContext, useObjectsContext } from "./SceneContext"
import RoundedRect from "./RoundedRect"
import Hud from "./Hud"

import { randomNumber, randomChoice, mapRange } from "./Utils"


const convertTransformInputToDragLimit = (input) => {
	const limits = input.map((val) => {
		if (val) {
			return null
		} else {
			return [0, 0]
		}
	})
	// console.log("limits", limits)
	return limits
}

export function ObjectCreator({hoveredSelection, type, onEnd}) {
  const store = useCreateStore()

  const [transformInput, setTransformInput] = useCameraContext()
  const transformLimits = useMemo(() => convertTransformInputToDragLimit(transformInput), [transformInput])

  const [isDrawing, setIsDrawing] = useState(false)
  const [startPosition, setStartPosition] = useState(new Vector3(0, 0, 0))
  const [selection, setSelection] = useSelectionContext()

  const [position, setPosition] = useState(new Vector3(0, 0, 0))
  const [rotation, setRotation] = useState([0, 0, 0])
  const [size, setSize] = useState(new Vector3(0, 0, .1))
  const [depth, setDepth] = useState(.1)

  const [text, setText] = useState("text")
  const [font, setFont] = useState('/Inter_Medium_Regular.json')

  const [alignX, setAlignX] = useState("right")
  const [alignY, setAlignY] = useState("top")
  const [alignZ, setAlignZ] = useState("back")

  const ref = useRef(null)

  useEffect(() => {
    setRotation(type === "circle" ? [Math.PI/2, Math.PI/2, 0] : [0, 0, 0])
    setText(type === "icon" ? "" : "text")
    setFont(type === "icon" ? '/Material_Icons_Outlined_Regular.json' : '/Inter_Medium_Regular.json')
  }, [type])

  // console.log("hover", props?.hoveredSelection[0]?.point)
  const setCalculatedSizeAndPosition = useCallback((startPosition, endPosition) => {
    // this is mount
    const delta = endPosition.clone().sub(startPosition.clone())
    const size = new Vector3(Math.abs(delta.x), Math.abs(delta.y), Math.abs(delta.z))
    // size args={[size.clone().distanceTo(new Vector3())/2, size.clone().distanceTo(new Vector3())/2, .1]}

    if (type === "frame") {
      setPosition(startPosition.clone().add(delta.clone().divideScalar(2)))
      setSize(size)
    } else if (type === "circle") {
      const fixedSize = endPosition.distanceTo(startPosition)/2

      let xFactor = 1
      let yFactor = 1

      if (delta.x < 0) {
        xFactor = -1
      }

      if (delta.y < 0) {
        yFactor = -1
      }

      setPosition(startPosition.clone().add(new Vector3(fixedSize*xFactor, fixedSize*yFactor, 0) ))
      setSize(new Vector3(fixedSize, fixedSize, .1))
    } else if (type === "text") {
      const fixedSize = endPosition.distanceTo(startPosition)

      let xFactor = 1
      let yFactor = 1

      if (delta.x < 0) {
        xFactor = -1
        setAlignX("left")
      }

      if (delta.y < 0) {
        yFactor = -1
        setAlignY("bottom")
      }

      setPosition(startPosition.clone())
      // const fixedSize = Math.max(size.x, size.y)/2
      setSize(new Vector3(fixedSize, fixedSize, .1))
      // console.log("del", delta.x, delta.y)
    } else if ( type === "icon") {
      const fixedSize = Math.max(size.x, size.y)
      setPosition(startPosition.clone().sub(new Vector3(fixedSize/2, fixedSize/2, 0)))
      setSize(new Vector3(fixedSize, fixedSize, .1))
    } else {
      setPosition(startPosition.clone().add(delta.clone().divideScalar(2)))
      const fixedSize = Math.max(size.x, size.y)/2
      setSize(new Vector3(fixedSize, fixedSize, .1))
    }
  }, [])

  const getDefaultSize = useCallback((type) => {
    const defaultSize = .25
    const defaultDepth = .1

    if (type === "frame") {
      return(new Vector3(defaultSize, defaultSize, defaultDepth))
    } else if ( type === "circle") {
      return(new Vector3(defaultSize/2, defaultSize/2, defaultDepth))
    } else if (type === "text") {
      return(new Vector3(defaultSize/2, defaultSize/2, defaultDepth))
    } else if ( type === "icon") {
      return(new Vector3(defaultSize, defaultSize, defaultDepth))
    } else {
      return(new Vector3(defaultSize, defaultSize, defaultDepth))
    }
  }, [])

  const padding = .1

  return(
    <>
      {/* helper plane where you draw */}
      <mesh
        position={[0, 0, startPosition.z]}
        onPointerDown={(e) => {
          e.stopPropagation()
          // console.log('pointdown', e, hoveredSelection)
          setIsDrawing(true)
          setSelection(null)
          const offset = new Vector3(0, 0, hoveredSelection?.[0]?.object?.uid ? hoveredSelection[0]?.point.clone().z/2 + padding : -.1/2)
          setStartPosition(e.point.add(offset))
          // setPosition(e.point)
        }}

        onPointerMove={(e) => {
          // console.log("pointer move", startPosition, e.point)
          e.stopPropagation()

          // todo: allegedly this is bad, try mutating state directly
          // ref.current.position.set(startPosition.clone().add(delta.clone().divideScalar(2)))
          if (isDrawing) {
            setCalculatedSizeAndPosition(startPosition, e.point)
          }
        }}

        onPointerUp={async (e) => {
          e.stopPropagation()
          setIsDrawing(false)

          // hack: if you make the thing too small, set a default size
          let normalizedSize
          if (startPosition.distanceTo(e.point) < .25) {
            normalizedSize = getDefaultSize(type)
          } else {
            setCalculatedSizeAndPosition(startPosition, e.point)
          }

          // tells parent donzo
          onEnd({
            store: store,
            position,
            rotation,
            size: normalizedSize || size,
            depth: normalizedSize?.z || size.z,
            font,
            text,
            type,
          })
        }}
      >
        <boxGeometry args={[100, 100, .1]} />
{/*
        <gridHelper
          args={[100, 100, "blue", "red"]}
          rotation={[Math.PI/2, 0, 0]}
        />
*/}
        <meshBasicMaterial color={"blue"} visible={false} />

        <Helper type={BoxHelper} args={['royalblue']} />
      </mesh>


      {/* the visualized element */}
      {isDrawing &&
        <mesh
          ref={ref}
          position={position}
          rotation={rotation}
        >
          {
            type === "frame" ?
              <boxGeometry
                args={Object.values(size)}
              />
            :
            type === "circle" ?
              <cylinderGeometry
                args={Object.values(size)}
              />
            :
            type === "text" ?
              <Center
                name="Text"
                scale={[1, 1, 1]}
                left={alignX === "left" ? true : false}
                right={alignX === "right" ? true : false}
                top={alignY === "top" ? true : false}
                bottom={alignY === "bottom" ? true : false}
                front={alignZ === "front" ? true : false}
                back={alignZ === "back" ? true : false}
              >
                <Text3D
                  castShadow
                  font={font}
                  scale={Object.values(size)}
                  letterSpacing={-0.03}
                  height={size.z + .1}
                >
                  {text}
                  <meshNormalMaterial />
                </Text3D>
              </Center>
            :
            type === "icon" ?
              <Center
                name="Icon"
                scale={[1, 1, 1]}

                // left={alignX === "left" ? true : false}
                // right={alignX === "right" ? true : false}
                // top={alignY === "top" ? true : false}
                // bottom={alignY === "bottom" ? true : false}
                // front={alignZ === "front" ? true : false}
                // back={alignZ === "back" ? true : false}
              >
                <Text3D
                  castShadow
                  font={font}
                  scale={Object.values(size)}
                  // scale={size.clone().distanceTo(new Vector3())}
                  letterSpacing={-0.03}
                  height={size.z + .1}
                >
                  {text}
                  <meshNormalMaterial />
                </Text3D>
              </Center>
            :
              null
          }
          <meshNormalMaterial />
        </mesh>
      }
    </>
  )
}


function Draggable({uid, draggable, get, set, children}) {
  const [transformInput, setTransformInput] = useCameraContext()
  const transformLimits = useMemo(() => convertTransformInputToDragLimit(transformInput), [transformInput])
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()

  // manually change matrix. see: https://github.com/pmndrs/drei?tab=readme-ov-file#dragcontrols
  const matrix = useRef(new Matrix4())
  const initPosition = useRef(new Vector3())

  return (
    <DragControls
      matrix={matrix.current}
      autoTransform={false}

      dragConfig={{enabled: draggable}} //disable it to prevent the weird propagation stuff
      onDragStart={(e) => {
        setDraggingSelection(uid)
        initPosition.current = new Vector3(...get[`position`])
      }}
      onDrag={(localMatrix, deltaLocalMatrix, worldMatrix, worldDeltaMatrix) => {
        const position = new Vector3().setFromMatrixPosition(localMatrix).add(initPosition.current)
        // console.log(position, initPosition.current, Object.values(position))
        set({position: Object.values(position) })
      }}
      onDragEnd={(e) => {
        setDraggingSelection(null)
      }}

      dragLimits={transformLimits}
    >
      {children}
    </DragControls>
  )
}

function Highlights({selected, hovered, outlines=false, edges=true}) {
  return (
    <>
      {outlines && (selected || hovered) ?
        <Outlines
          renderOrder={1000}
          screenspace
          transparent
          color={selected ? "#0077ff" : "white"}
          opacity={(selected || hovered) * 1}
          thickness={8}
        />
      : edges && (selected || hovered) ?
        <Edges
          linewidth={4}
          scale={1}
          threshold={1} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
          // opacity={(selected || hovered) * 1}
          color={selected ? "#0077ff" : "white"}
        />
      : (selected || hovered) &&
        <Helper type={BoxHelper} args={['royalblue']} />
      }
    </>
  )
}

function handleClick(e, uid, draggingSelection, selected, setSelection) {
  // important: if i stop prop, the object creator wont release if pointer up over another object
  // e.stopPropagation()

  if (!selected) {
    if (!draggingSelection || draggingSelection === uid) {
      setSelection(uid)
    }
  }
}

function updateObject(setObjects, {uid, ref, store}) {
  setObjects(oldObjects => {
    const object = {
      ...oldObjects[uid],
      ref: ref.current,
      store: store
    }

    return (
      {
        ...oldObjects,
        [uid]: object,
      })
  })
}

function editableMaterialProps({type, ...props}) {
  // console.log('color', color)
  let materialProps = {}

  if (type === "standard") {
    materialProps = {
      emissive: {
        value: "#000000",
      },
      roughness: {
        value: 1,
        min: 0,
        max: 1,
      },
      metalness: {
        value: 0,
        min: 0,
        max: 1,
      },
    }
  } else if (type === "transmission") {
    materialProps = {
      samples: { value: 16, min: 1, max: 32, step: 1 },
      resolution: { value: 256, min: 64, max: 2048, step: 64 },
      clearcoat: { value: 0.1, min: 0, max: 1, step: 0.01 },
      clearcoatRoughness: { value: 0.1, min: 0, max: 1, step: 0.01 },
      backsideThickness: { value: 200, min: 0, max: 200, step: 0.01 },
      ior: { value: 1.5, min: 1, max: 5, step: 0.01 },
      chromaticAberration: { value: 1, min: 0, max: 1 },
      anisotropy: { value: 1, min: 0, max: 10, step: 0.01 },
      distortion: { value: 0, min: 0, max: 1, step: 0.01 },
      distortionScale: { value: 0.2, min: 0.01, max: 1, step: 0.01 },
      temporalDistortion: { value: 0, min: 0, max: 1, step: 0.01 },
      attenuationDistance: { value: 0.5, min: 0, max: 10, step: 0.01 },
      attenuationColor: '#ffffff',

      roughness: { value: 0.5, min: 0, max: 1 },
      thickness: { value: 0.25, min: 0, max: 5 },
      envMapIntensity: { value: 1, min: 0, max: 10 },
      transmission: { value: 1, min: 0, max: 1 },
      metalness: { value: .5, min: 0, max: 1 },
      // transmission: { value: 0.95, min: 0, max: 1 },
      // roughness: { value: 0.5, min: 0, max: 1, step: 0.01 },
      // thickness: { value: 200, min: 0, max: 200, step: 0.01 },
    }
  }

  return materialProps
}

function StandardMaterial({type, store, src, ...rest}) {
  // see: https://codesandbox.io/p/sandbox/spline-glass-shapes-ju368j?file=%2Fsrc%2FApp.js%3A48%2C31
  const [texture, setTexture] = useState(null)
  const material = useRef(null)
  const presetEnvironmentTexture = useEnvironment({ preset: 'city' })

  const [get, set] = useControls(`Material`, () => ({
    ...editableMaterialProps({type, ...rest}),
    image: {
      image: src || undefined,
      onChange: (file) => {
        if (file) {
          console.log("image change", file)
          store?.set({"Material.color": "#ffffff" })
          setTexture(file)
        }
      }
    },
  }), { store: store })

  // don't pass a material tex map prop to the return component since we load it here
  useEffect(() => {
    // console.log('mat', texture, material)
    if (texture && material.current) {
      const textureLoader = new TextureLoader()

      // hack since chrome caches images without headers see: https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
      // console.log("cache texture", texture.includes("blob:"))
      textureLoader.load(`${texture}${texture?.includes("blob:") ? "" : "?not-from-cache-please"}`, (t) => {
        material.current.map = t
        t.colorSpace = SRGBColorSpace //important to make textures not washed out

        material.current.needsUpdate = true
      },
      // onProgress callback currently not supported
      undefined,

      // onError callback
      function (error) {
        console.log('image error', texture, error)
      })
    }
  }, [texture, material])

  return (
    <meshStandardMaterial
      ref={material}
      {...rest}
      {...get}
      onUpdate={self => self.needsUpdate = true}
      // envMap={presetEnvironmentTexture}
    />
  )

}

function TransmissionMaterial({type, src, store, ...rest}) {
  // see: https://codesandbox.io/p/sandbox/spline-glass-shapes-ju368j?file=%2Fsrc%2FApp.js%3A48%2C31
  const [texture, setTexture] = useState(null)
  const material = useRef(null)
  const presetEnvironmentTexture = useEnvironment({ preset: 'city' })

  // console.log("type", type, store, rest)
  const [get, set] = useControls(`Material`, () => ({
    ...editableMaterialProps({type, ...rest}),
    image: {
      image: src || undefined,
      onChange: (file) => {
        if (file) {
          console.log("image change", file)
          store?.set({"Material.color": "#ffffff" })
          setTexture(file)
        }
      }
    },
  }),
    { collapsed: true },
    { store: store }
  )

  // don't pass a material tex map prop to the return component since we load it here
  useEffect(() => {
    if (texture && material.current) {
      const textureLoader = new TextureLoader()

      textureLoader.load(texture, (t) => {
        material.current.map = t
        t.colorSpace = SRGBColorSpace //important to make textures not washed out
        material.current.needsUpdate = true
      })
    }
  }, [texture, material])

  return (
    <>

    <MeshTransmissionMaterial
      ref={material}
      {...rest}
      {...get}
      // depthTest={false}
      // depthWrite={false}
      toneMapped={false}

      onUpdate={self => self.needsUpdate = true}
      envMap={presetEnvironmentTexture}
    />
    </>
  )
}

function EditableMaterial({store, type="standard", transparent=false, opacity=1, color="#cccccc", ...rest}) {
  const [get, set] = useControls(`Material`, () => ({
    type: {
      value: type,
      options: ['standard', 'transmission', 'refraction', 'normal'],
    },
    color: {
      label: "color",
      value: color,
      optional: true,
      disabled: false
    },
    transparent: { value: transparent },
    opacity: {
      value: opacity,
      min: 0,
      max: 1,
    },
    side: {
      value: DoubleSide,
    },
    castShadow: { value: true },
    receiveShadow: { value: true },
  }), { store: store })

  // useEffect(() => {
  //   console.log('type', get["type"])
  // }, [get["type"]])

  return (
    <>
      {
        get[`type`] === "standard" ?
          <StandardMaterial
            store={store}
            {...get}
            {...rest} // core and rest of defaults passed
          />
        : get[`type`] === "refraction" ?
          <MeshRefractionMaterial
            // ref={material}
            // envMap={env}
            toneMapped={false}
            onUpdate={self => self.needsUpdate = true}
          />
        : get[`type`] === "transmission" ?
          <TransmissionMaterial
            store={store}
            {...get}
            {...rest}
          />
        :
          <meshNormalMaterial />
      }
    </>
  )
}

export function Cube({uid, i, selected, hovered, draggable, ...props}) {
  const store = useCreateStore()
  const ref = useRef()

  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()
  const [objects, setObjects] = useObjectsContext()

  useEffect(() => {
    updateObject(setObjects, {uid, ref, store})
  }, [])

  const randomSize = useMemo(() => randomNumber(.5, 1), [])
  const randomRotation = useMemo(() => randomNumber(-Math.PI, Math.PI), [])
  const randomColor = useMemo(() => randomChoice([
    "hotpink", "tomato", "turquoise", "whitesmoke", "yellow", "slategrey", "indigo", "dodgerblue", "aquamarine", "black"
  ]), [])

  const randomPosition = useMemo(() => [
      randomNumber(-4, 4),
      randomNumber(randomSize, 4),
      randomNumber(-4, 4)
  ], [])

  const [get, set] = useControls(`Cube ${i+1}`, () => ({
    position: {
      label: "position",
      value: randomPosition,
      step: .05,
    },
    rotation: {
      label: "rotation",
      value: [0, randomRotation, 0],
      step: .05,
    },
    size: {
      label: "size",
      value: [randomSize, randomSize, randomSize],
      min: 0,
      step: .05,
      lock: true,
    },
    locked: {
      value: false,
    }
  }), { store: store })

  return (
    <Draggable
      uid={uid}
      get={get}
      set={set}
      draggable={!get["locked"] && draggable}
    >
      <mesh
        {...props}
        ref={ref}
        uid={uid}
        castShadow={get["castShadow"]}
        receiveShadow={get["receiveShadow"]}

        position={get[`position`]}
        rotation={get[`rotation`]}

        onPointerUp={(e) => handleClick(e, uid, draggingSelection, selected, setSelection)}
      >
        <boxGeometry args={get["size"]} />

        <EditableMaterial
          store={store}
          color={randomColor}
        />

        <Highlights selected={selected} hovered={hovered} />
      </mesh>
      <Hud
        store={store}
        get={get}
        selected={selected}
      />
    </Draggable>
  )
}

export function Sphere({uid, position, rotation, size, selected, hovered, draggable, src, ...props}) {
  const store = useCreateStore()
  const ref = useRef()

  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()
  const [objects, setObjects] = useObjectsContext()

  useEffect(() => {
    updateObject(setObjects, {uid, ref, store})
  }, [])

  const randomSize = useMemo(() => randomNumber(.5, 1), [])
  const randomColor = useMemo(() => randomChoice([
    "hotpink", "tomato", "turquoise", "whitesmoke", "yellow", "slategrey", "indigo", "dodgerblue", "aquamarine", "black"
  ]), [])

  const randomPosition = useMemo(() => [
      randomNumber(-4, 4),
      randomNumber(randomSize, 4),
      randomNumber(-4, 4)
  ], [])

  const [get, set] = useControls(`Sphere ${props.i+1}`, () => ({
    [`position`]: {
      label: "position",
      value: position || randomPosition,
      step: .05,
    },
    [`rotation`]: {
      label: "rotation",
      value: rotation || [0, 0, 0],
      step: .05,
    },
    [`size`]: {
      label: "size",
      value: size || randomSize,
      min: 0,
      step: .05,
      lock: true,
    },
    locked: {
      value: false,
    }
  }), { store: store })

  return (
    <Draggable
      uid={uid}
      get={get}
      set={set}
      draggable={!get["locked"] && draggable}
    >
      <mesh
        {...props}
        ref={ref}
        uid={uid}
        castShadow={get["castShadow"]}
        receiveShadow={get["receiveShadow"]}

        position={get[`position`]}
        rotation={get[`rotation`]}

        onPointerUp={(e) => handleClick(e, uid, draggingSelection, selected, setSelection)}
      >
        <sphereGeometry args={[get[`size`], 64, 64]} />
        <EditableMaterial
          store={store}
          color={src ? "#ffffff" : randomColor}
          src={src}
        />

        <Highlights selected={selected} hovered={hovered} />
      </mesh>
      <Hud
        store={store}
        get={get}
        selected={selected}
        padding={.5}
        size={[get[`size`], get[`size`], get[`size`]]}
      />
    </Draggable>
  )
}

export function Frame({ uid, src, children, draggable, selected, hovered, ...props }) {
  const store = useCreateStore()
  const ref = useRef()

  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()
  const [objects, setObjects] = useObjectsContext()

  useEffect(() => {
    updateObject(setObjects, {uid, ref, store})
  }, [])


  const [get, set] = useControls(`Frame ${props.i+1}`, () => ({
    [`position`]: {
      label: "position",
      value: Object.values(props.position) || [0, 0, 0],
      // value: { x: -50, y: 0 },
      step: .05,
    },
    [`rotation`]: {
      label: "rotation",
      value: [0, 0, 0],
      step: .05,
    },
    [`size`]: {
      label: "size",
      value: Object.values(props.size) || [1, 1, 1],
      min: 0,
      step: .05,
      lock: true,
    },
    [`border`]: {
      label: "radius",
      value: { size: Math.min(Math.min(props.size.x, props.size.y), .2)/2, smoothness: 2 },
      joystick: false,
      min: 0,
      step: .01,
    },
    locked: {
      value: false,
    }
    // backside: true,
  }), { store: store })

  return (
    <Draggable
      uid={uid}
      get={get}
      set={set}
      draggable={!get["locked"] && draggable}
    >
      <RoundedRect
        {...props}
        ref={ref}
        uid={uid}

        args={get[`size`]}
        radius={get[`border`]["size"]} // Radius of the rounded corners. Default is 0.05
        smoothness={get[`border`["smoothness"]]}

        position={get[`position`]}
        rotation={get[`rotation`]}

        castShadow={get["castShadow"]}
        receiveShadow={get["receiveShadow"]}

        onPointerUp={(e) => handleClick(e, uid, draggingSelection, selected, setSelection)}
      >
        <EditableMaterial
          store={store}
          color={src ? "#fff" : "#ccc"}
        />
        <Highlights selected={selected} hovered={hovered} />
      </RoundedRect>

      <Hud
        store={store}
        get={get}
        selected={selected}
      />
    </Draggable>
  )
}

export function Circle({ uid, children, draggable, selected, hovered, ...props }) {
  const store = useCreateStore()
  const ref = useRef()

  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()
  const [objects, setObjects] = useObjectsContext()

  useEffect(() => {
    updateObject(setObjects, {uid, ref, store})
  }, [])

  const [get, set] = useControls(`Circle ${props.i+1}`, () => ({
    [`position`]: {
      label: "position",
      value: Object.values(props.position) || [0, 0, 0],
      // value: { x: -50, y: 0 },
      step: .05,
    },
    [`rotation`]: {
      label: "rotation",
      value: props.rotation || [0, 0, 0],
      step: .05,
    },

    [`size`]: {
      label: "size",
      value: {
        radius: props.size.x,
        depth: props.size.z,
        smoothness: 24
      },
      min: 0,
      step: .1,
      lock: true,
    },
    locked: {
      value: false,
    }
    // backside: true,
  }), { store: store })

  return (
    <Draggable
      uid={uid}
      get={get}
      set={set}
      draggable={!get["locked"] && draggable}
    >
      <Cylinder
        {...props}
        uid={uid}
        ref={ref}
        args={[get[`size`]["radius"], get[`size`]["radius"], get[`size`]["depth"], Math.max(1, get[`size`]["smoothness"])]}
        position={get[`position`]}
        rotation={get[`rotation`]}
        castShadow={get["castShadow"]}
        receiveShadow={get["receiveShadow"]}
        onPointerUp={(e) => handleClick(e, uid, draggingSelection, selected, setSelection)}
      >
        <EditableMaterial store={store} />
        <Highlights selected={selected} hovered={hovered} />
      </Cylinder>

      <Hud
        store={store}
        get={get}
        selected={selected}
        size={[get[`size`]["radius"]*2, get[`size`]["radius"]*2, get[`size`]["depth"]]}
      />
    </Draggable>
  )
}

export function TextField({uid, children, i, draggable, selected, hovered, type, font, ...props }) {
  const store = useCreateStore()

  const ref = useRef()
  const textRef = useRef()
  const boxRef = useRef()
  const cursorRef = useRef()
  const isFirstInput = useRef(true)

  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()
  const [objects, setObjects] = useObjectsContext()
  const [focused, setFocused] = useState(true)

  useEffect(() => {
    updateObject(setObjects, {uid, ref, store})
  }, [])


  const getInputHelper = useCallback(() => {
    return document.getElementById("inputTextHelper")
  }, [])

  const handleInput = useCallback((e) => {
    console.log("does this fire")
    set({[`text`]: e.target.value })
    isFirstInput.current = false
  }, [])

  useEffect(() => {
    // todo: if mounting, make the text feel like hint text
    const inputHelper = getInputHelper()

    if (focused) {
      if (!isFirstInput.current) {
        // if it's not the first time, then resume text storage
        inputHelper.value = get[`text`]
      }

      inputHelper.disabled = false
      inputHelper?.focus()

      inputHelper?.addEventListener("input", handleInput)
    } else {
      inputHelper.disabled = true
      inputHelper?.removeEventListener("input", handleInput)
    }

    return () => {
      console.log("cleanup")
      inputHelper?.removeEventListener("input", handleInput)
    }
  }, [focused])

  // console.log("val", font, props.text)
  const [get, set] = useControls(`Text ${i+1}`, () => ({
    [`text`]: {
      label: "text",
      value: props.text,
    },
    [`position`]: {
      label: "position",
      value: Object.values(props.position) || [0, 0, 0],
      step: .05,
    },
    [`rotation`]: {
      label: "rotation",
      value: [0, 0, 0],
      step: .05,
    },
    [`size`]: {
      label: "size",
      value: {height: props.size.y || 1, depth: props.depth || .01},
      min: 0,
      joystick: false,
      step: .05,
      lock: true,
    },
    alignX: {
      value: 'right',
      options: [
        'left',
        'center',
        'right',
      ]
    },
    alignY: {
      value: 'top',
      options: [
        'top',
        'center',
        'bottom',
      ]
    },
    alignZ: {
      value: 'back',
      options: [
        'front',
        'center',
        'back',
      ]
    },
    locked: {
      value: false,
    }
  }), { store: store })

  useFrame(() => {
    // console.log('helper', textRef.current.scale.x, textRef.current.geometry.boundingBox?.max.x, textRef.current.position.x)
    const xFactor = get["alignX"] === "left" ? -1 : get["alignX"] === "right" ? 1 : 0
    const yFactor = get["alignY"] === "bottom" ? -1 : get["alignY"] === "top" ? 1 : 0
    const zFactor = get["alignZ"] === "back" ? -1 : get["alignZ"] === "front" ? 1 : 0

    if (boxRef.current) {
      boxRef.current.position.x = (textRef.current.scale.x * (textRef.current.geometry.boundingBox?.max.x)/2 * xFactor)
      boxRef.current.position.y = (textRef.current.scale.y * (textRef.current.geometry.boundingBox?.max.y)/2 * yFactor)
      boxRef.current.position.z = (textRef.current.scale.z * (textRef.current.geometry.boundingBox?.max.z)/2 * zFactor)

      boxRef.current.scale.x = (textRef.current.scale.x * textRef.current.geometry.boundingBox?.max.x)
      boxRef.current.scale.y = (textRef.current.scale.y * textRef.current.geometry.boundingBox?.max.y)
      boxRef.current.scale.z = (textRef.current.scale.z) // we don't need the bbox since we know the depth
    }

    if (focused && cursorRef.current) {
      cursorRef.current.position.x = (textRef.current.scale.x * textRef.current.geometry.boundingBox?.max.x) * (mapRange(xFactor, -1, 1, 0, 1)) + .1
      cursorRef.current.position.y = (textRef.current.scale.y * textRef.current.geometry.boundingBox?.max.y)/2 * yFactor
      cursorRef.current.scale.y = (textRef.current.scale.y * textRef.current.geometry.boundingBox?.max.y) * 1.1

      // check for focus
      const inputHelper = getInputHelper()
      const isInputFocused = (document.activeElement === inputHelper)
      if (!isInputFocused && !inputHelper.disabled) {
        // console.log("document.activeElement")
        if (document.activeElement.tagName !== "SELECT") {
          inputHelper.focus()
        }
      }

      // if disabled from outside, set internal focus out
      if (inputHelper.disabled && focused) {
        setFocused(false)
      }
    }
  })

  // console.log("tags", get["alignX"].right)

  return (
    <Draggable
      uid={uid}
      get={get}
      set={set}
      draggable={!get["locked"] && draggable}
    >
      <group
        {...props}
        uid={uid}
        ref={ref}

        position={get[`position`]}
        rotation={get[`rotation`]}

        onPointerUp={(e) => handleClick(e, uid, draggingSelection, selected, setSelection)}
        onDoubleClick={() => setFocused(true)}
        onPointerMissed={() => setFocused(false)}
      >
        <Center
          left={get["alignX"] === "left" ? true : false}
          right={get["alignX"] === "right" ? true : false}
          top={get["alignY"] === "top" ? true : false}
          bottom={get["alignY"] === "bottom" ? true : false}
          front={get["alignZ"] === "front" ? true : false}
          back={get["alignZ"] === "back" ? true : false}
          // onCentered={({ container, height }) => console.log("center", container, height)}>
        >
          <Text3D
            name={props.name}
            uid={uid}
            ref={textRef}

            castShadow={get["castShadow"]}
            receiveShadow={get["receiveShadow"]}

            font={font}
            scale={get[`size`]["height"]}
            letterSpacing={-0.03}
            height={get[`size`]["depth"]}
            bevelEnabled={false}
            // bevelSize={0.01}
            // bevelSegments={1}
            // curveSegments={128}
            // bevelThickness={0.01}
          >
            {get[`text`]}

            <EditableMaterial
              store={store}
              color={"#ffffff"}
            />
            <Highlights selected={selected} hovered={hovered} outlines={false} />
          </Text3D>

        </Center>
        <Box
          uid={uid}
          ref={boxRef}
          args={[1, 1, get[`size`]["depth"]]}
        >
          <meshBasicMaterial
            transparent
            color={"blue"}
            opacity={0}
          />
        </Box>

        {/* blinking text cursor */}
        {focused &&
          <Box
            ref={cursorRef}
            args={[.05, 1, .05]}
          >
            <motion.meshStandardMaterial
              color="blue"
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              transition={{duration: .5, repeatType: "reverse", repeatDelay: .5, repeat: Infinity}}
            />
          </Box>
        }
      </group>

      {type === "icon" &&
        <Hud
          store={store}
          get={get}
          selected={selected}
          size={[get[`size`]["height"]*2, get[`size`]["height"]*2, get[`size`]["depth"]]}
        />
      }
    </Draggable>
  )
}

export function ImageFrame({src, uid, i, color, selected, hovered, draggable=false, name, ...props}) {
  const store = useCreateStore()
  const ref = useRef()

  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()
  const [objects, setObjects] = useObjectsContext()

  useEffect(() => {
    updateObject(setObjects, {uid, ref, store})
    // console.log('parent color', src, color || "#ffffff")
  }, [])

  const [get, set] = useControls(`Image ${i+1}`, () => ({
    [`position`]: {
      label: "position",
      value: Object.values(props.position) || [0, 0, 0],
      step: .05,
    },
    [`rotation`]: {
      label: "rotation",
      value: [0, 0, 0],
      step: .05,
    },
    [`size`]: {
      label: "size",
      value: Object.values(props.size) || [1, 1, .1],
      // value: [1, 1, .1],
      min: 0.01,
      step: .05,
      lock: true,
    },
    [`border`]: {
      label: "radius",
      // value: { size: Math.min(Math.min(props.size.x, props.size.y), .2)/2, smoothness: 2 },
      value: {
        size: props.size ? 0.001 : .1,
        smoothness: 2
      },
      joystick: false,
      min: 0,
      step: .01,
    },
    locked: {
      value: false,
    }
  }), { store: store })

  useEffect(() => {
    if (src) {
      const img = new Image()
      img.src = src

      img.onload = () => {
        // const scale = useAspect(img.width, img.height, 1)
        const maxSide = img.height //Math.max(img.width, img.height)
        const maxSize = 3
        const scaleFactor = maxSize / maxSide
        // console.log("image load", img.width, img.height, scale)
        if (!props.size) {
          set({size: [img.width * scaleFactor, img.height * scaleFactor, .1]})
          set({position: [0, img.height * scaleFactor / 2, 0]})
        }
      }
      img.onerror = (e) => {
        console.log("image error", src, e)
      }
    }
  }, [src])

  // useLayoutEffect(() => {

  // }, [store])

  return (
    <Draggable
      uid={uid}
      get={get}
      set={set}
      draggable={!get["locked"] && draggable}
    >
      <RoundedRect
        {...props}
        ref={ref}
        uid={uid}
        renderOrder={i} // this fixes transparency issues see: https://discourse.threejs.org/t/threejs-and-the-transparent-problem/11553
        args={get[`size`]}
        radius={get[`border`]["size"]} // Radius of the rounded corners. Default is 0.05
        smoothness={get[`border`["smoothness"]]}

        position={get[`position`]}
        rotation={get[`rotation`]}
        castShadow={get["castShadow"]}
        receiveShadow={get["receiveShadow"]}
        onPointerUp={(e) => handleClick(e, uid, draggingSelection, selected, setSelection)}
      >
        <EditableMaterial
          store={store}
          transparent={true}
          opacity={1}
          color={src ? "#ffffff" : color ? color : "#ffffff"}
          src={src}
        />

        <Highlights outlines={false} edges={true} selected={selected} hovered={hovered} />
      </RoundedRect>

      <Hud
        store={store}
        get={get}
        selected={selected}
      />
    </Draggable>
  )
}


export function GLTF({src, uid, i, selected, hovered, draggable=false, position, rotation, size, name, type="gltf", ...props}) {
  const store = useCreateStore()
  const ref = useRef()
  const boxRef = useRef()
  const groupRef = useRef()

  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()
  const [objects, setObjects] = useObjectsContext()

  // const { scene, nodes, materials } = useGLTF('/911-transformed.glb')
  const ktxLoader = new KTX2Loader()

  const { scene } = useGLTF(src, '/draco-gltf', false, (loader) => {
    ktxLoader.setTranscoderPath('three/examples/js/libs/basis/')
    // ktxLoader.detectSupport(state.gl)
    loader.setKTX2Loader(ktxLoader)
  })

  useEffect(() => {
    updateObject(setObjects, {uid, ref, store})
  }, [])

  const [get, set] = useControls(name || `${type} ${i+1}`, () => ({
    position: {
      value: position || [0, 0, 0],
      step: .05,
    },
    rotation: {
      value: rotation || [0, 0, 0],
      step: .05,
    },
    size: {
      value: size || [1, 1, 1],
      min: 0.005,
      step: .001,
      lock: true,
      locked: false,
    },
    locked: {
      value: false,
    }
  }), { store: store })

  useEffect(() => {
    console.log("cleanup", src, size)

    // cleanup url from memory
    // todo: not sure if this is necessary since i'm actually using the blobs
    URL.revokeObjectURL(src)

    // init a normalized size if no size
    if (!size) {
      const {bboxCenter, bboxSize} = getBoundingBox(ref)

      const maxSize = 5
      const largestSide = Math.max(...Object.values(bboxSize))

      if (largestSide > maxSize) {
        set({size: [maxSize/largestSide, maxSize/largestSide, maxSize/largestSide]})
      }
    }
  }, [scene])

  useFrame(() => {
    if (boxRef.current) {
      const {bboxCenter, bboxSize} = getBoundingBox(ref)

      if (bboxSize) {
        boxRef.current.scale.x = bboxSize.x
        boxRef.current.scale.y = bboxSize.y
        boxRef.current.scale.z = bboxSize.z
      }

      if (bboxCenter) {
        boxRef.current.position.x = bboxCenter.x
        boxRef.current.position.y = bboxCenter.y
        boxRef.current.position.z = bboxCenter.z
      }
    }
  })

  // console.log("uid", uid)
  return (
    <Draggable
      renderOrder={50}

      uid={uid}
      get={get}
      set={set}
      draggable={!get["locked"] && draggable}
    >
      <group
        uid={uid}
        ref={groupRef}
      renderOrder={50}
        
      >
        <primitive
          {...props}
          renderOrder={50}
          ref={ref}
          uid={uid}
          castShadow={get["castShadow"]}
          receiveShadow={get["receiveShadow"]}

          position={get[`position`]}
          rotation={get[`rotation`]}
          scale={get[`size`]}

          onPointerUp={(e) => handleClick(e, uid, draggingSelection, selected, setSelection)}
          object={scene}
        />
      </group>

      <Box
        uid={uid}
        ref={boxRef}

        castShadow={false}
        receiveShadow={false}

        position={get[`position`]}
        rotation={get[`rotation`]}
        scale={get[`size`]}
        args={[1, 1, 1]}
        renderOrder={i+100}
      >
        <meshBasicMaterial
          // visible={selected || hovered ? true : false}
          // visible={false}

          // depthTest={false}
          // depthWrite={false}

          transparent
          // color={"red"}
          opacity={0}
        />

        <Highlights selected={selected} hovered={hovered} />
      </Box>
    </Draggable>
  )
}

function getBoundingBox(objectRef) {
  const bbox = new Box3().setFromObject(objectRef.current)
  const bboxSize = bbox.getSize(new Vector3())
  const bboxCenter = bbox.getCenter(new Vector3())

  return {bboxCenter, bboxSize}
}

export const Grid = ({number = 24, lineWidth = 0.025, height = 0.25, tons=false}) => (
  // Renders a grid and crosses as instances
  <Instances position={[0, 0, 0]}>
    <planeGeometry args={[lineWidth, height]} />
    <meshBasicMaterial color="#fff" />

    {tons ?
      Array.from({ length: number }, (_, y) =>
      Array.from({ length: number }, (_, x) => (
        <group key={x + ':' + y} position={[x * 2 - Math.floor(number / 2) * 2, -0.01, y * 2 - Math.floor(number / 2) * 2]}>
          <Instance rotation={[-Math.PI / 2, 0, 0]} />
          <Instance rotation={[-Math.PI / 2, 0, Math.PI / 2]} />
        </group>
      ))
    )
      :
        <group key={"0:0"} position={[0, -0.01, 0]}>
          <Instance rotation={[-Math.PI / 2, 0, 0]} />
          <Instance rotation={[-Math.PI / 2, 0, Math.PI / 2]} />
        </group>
    }
    <gridHelper args={[100, 400, '#999', '#999']} position={[0, -0.02, 0]} />
  </Instances>
)

export function Light({uid, i, selected, hovered, draggable, ...props}) {
  const store = useCreateStore()
  const ref = useRef()

  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()
  const [objects, setObjects] = useObjectsContext()

  useEffect(() => {
    updateObject(setObjects, {uid, ref, store})
  }, [])

  const randomSize = useMemo(() => randomNumber(.5, 1), [])
  const randomRotation = useMemo(() => randomNumber(-Math.PI, Math.PI), [])
  const randomColor = useMemo(() => randomChoice([
    "hotpink", "tomato", "turquoise", "whitesmoke", "yellow", "slategrey", "indigo", "dodgerblue", "aquamarine", "black"
  ]), [])

  const randomPosition = useMemo(() => [
      randomNumber(-4, 4),
      randomNumber(randomSize, 4),
      randomNumber(-4, 4)
  ], [])

  const [get, set] = useControls(`Light ${i+1}`, () => ({
    position: {
      label: "position",
      value: randomPosition,
      step: .05,
    },
    // rotation: {
    //   label: "rotation",
    //   value: [0, randomRotation, 0],
    //   step: .05,
    // },
    shadow: {
      value: true,
    },
    intensity: {
      value: 1,
      min: 0,
      step: .05,
    },
    distance: {
      value: 0,
      min: 0,
      step: .05,
    },
    locked: {
      value: false,
    }
  }), { store: store })

  return (
    <Draggable
      uid={uid}
      get={get}
      set={set}
      draggable={!get["locked"] && draggable}
    >
      <pointLight
        // {...props}
        ref={ref}
        uid={uid}

        castShadow={get[`shadow`]}
        intensity={get[`intensity`]}
        distance={get[`distance`]}

        position={get[`position`]}
        // rotation={get[`rotation`]}

        onPointerUp={(e) => handleClick(e, uid, draggingSelection, selected, setSelection)}
      >

        <Helper type={PointLightHelper} />

        {/*<Highlights selected={selected} hovered={hovered} />*/}
      </pointLight>



{/*      <Hud
        store={store}
        get={get}
        selected={selected}
      />*/}
    </Draggable>
  )
}

const createImageUrl = (buffer, type) => {
  const blob = new Blob([buffer], { type })
  const urlCreator = window.URL || window.webkitURL
  const imageUrl = urlCreator.createObjectURL(blob)
  console.log(imageUrl)
  return imageUrl
}