import MaterialSymbol from "./MaterialSymbol"
import { motion } from "framer-motion"
import { useState, useMemo, useEffect } from 'react'
import {isIOS} from './Utils'

export default function Sidebar({name, children, icon="tune", className, onClick}) {
  const [isOpen, setIsOpen] = useState(false)
  const ios = useMemo(() => isIOS(), [])

  return (
    <div
      className={`absolute right-0 top-0`}
    >
      {ios ?
        <>
{/*          <button
            // class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            className={`absolute top-0 right-0 z-50 m-4 flex items-center pointer-events-auto capitalize rounded text-sm ${icon ? "pl-2 pr-1" : "px-2"} py-1 ${className}`}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <MaterialSymbol className="text-white" name={"tune"} />
          </button>

          <motion.div
            className="z-40 absolute top-0 right-0 origin-top-left"
            // style={{zIndex: 50000}}
            animate={{
              x: isOpen ? 0 : 300,
              y: 0
            }}
            transition={{type: "spring", stiffness: 400, damping: 24}}
            // pointerOut={() => {
            //   console.log('point out')
            // }}
          >
            {children}
          </motion.div>*/}
        </>
        :
        <>
        {children}
        </>
      }
    </div>
  )
}
