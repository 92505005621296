// query for groq ai
import { Functions } from './FirebaseAPI'
import { cleanJSON } from './Utils'

const debug = false

export const generateChatCompletion = ({systemMessage, chats}) => {
	const generateCompletion = Functions('groq-getChatCompletion')

	const n = 10
	chats = chats.slice(-n)

	// remove chats with images
	chats = chats.filter((chat) => !chat?.image)

	const messages = [
	    {
	        role: "system",
	        content: systemMessage
	    },
		...chats
	]
	// const model = "mixtral-8x7b-32768"
	// const model = "llama-3.2-3b-preview" //"llama3-70b-8192"
	// const model = "llama3-70b-8192"
	const model = "llama-3.1-70b-versatile"
	console.log("start ai", messages)

	return new Promise((resolve, reject) => {
		if (debug) {
			const debugText = `{ "description": "Unleash your creativity and design skills in the world of Spatial! Build your own virtual masterpieces and showcase your unique style. It's time to let your imagination run wild and create wonders like never before.", "emoji": "", "tagline": "Design your dreams. Play Now!" }`
			const json = JSON.parse(debugText)
			// console.log("resolve", json)

			resolve(json)
		} else {
			generateCompletion({messages: messages, model: model, json: true}).then(async (res) => {
				console.log("pre result", res.data)

				let result = {}

				try {
			        result = JSON.parse(res.data.content)
			    } catch (e) {
			    	console.warn('no json', res.data)
			    	result = cleanJSON(res.data.content)
			    }

				result.role = res.data.role
				console.log("result", result)
				// const json = JSON.parse(res.data?.text)
				// console.log('ai success', json, res)
				// const formattedData = await setFirestoreTag(tagName, json)

				resolve(result)
			}).catch((error) => {
				console.log('ai error', error)
				reject("error", error)
			})
		}
	})
}