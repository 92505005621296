import { initializeApp, getApps } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyCYZT3kV-1dlOLrRatXm2P_bsjp0cwliKw",
  authDomain: "spatial-character.firebaseapp.com",
  projectId: "spatial-character",
  storageBucket: "spatial-character.appspot.com",
  messagingSenderId: "362926491000",
  appId: "1:362926491000:web:380a2ee96bacfd7967748d",
  measurementId: "G-95EZ0Z4TXZ"
}

export const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0]
export const db = getFirestore(firebaseApp)
// export const auth = getAuth(firebaseApp)
// export const storage = getStorage(firebaseApp)

export const performance = firebaseApp.name && typeof window !== "undefined" ? getPerformance(firebaseApp) : null
// console.log("perf", performance)
export const analytics = firebaseApp.name && typeof window !== "undefined" ? getAnalytics(firebaseApp) : null

export function log(name, content) {
    if (analytics) {
        logEvent(analytics, name, content)
    }
}

// get functions
const appFunctions = getFunctions(firebaseApp)
export const Functions = (name) => httpsCallable(appFunctions, name)

// for local debugging
const debug = false

console.log("is local", window.location.href.includes("localhost"))

if (debug && window.location.href.includes("localhost")) {
    connectFunctionsEmulator(appFunctions, "localhost", 5001);
}