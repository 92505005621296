// query for openai ai
import { Functions } from './FirebaseAPI'
import { setIsTalking, registerCallback } from '../apis/talkingHead'

const debug = false

// Add at the top with other constants
let activeAudioContext = null;
let activeCleanupFunction = null;

export const getVoices = async (gender="female") => {
  const generateCompletion = Functions('elevenLabs-getVoices')

  const response = await generateCompletion()
  const filteredVoices = response?.data?.voices.filter(voice => 
    voice.labels?.gender === "female"
  )

  return filteredVoices
}

export const generateSpeech = async ({message, voice, mood}) => {
  // Cleanup any existing audio processing
  if (activeCleanupFunction) {
    await activeCleanupFunction();
  }

  if (activeAudioContext && activeAudioContext.state !== 'closed') {
    await activeAudioContext.close();
  }

  const audioContext = new AudioContext();
  activeAudioContext = audioContext;  // Store reference

  // const generateCompletion = Functions('openAI-generateSpeech')
  const generateCompletion = Functions('elevenLabs-generateSpeech')

  const cleanedMessage = message.replace(/\p{Emoji}/gu, '')
  const formattedText = `<${mood} /> ${cleanedMessage}`
  console.log("formatted speech", formattedText)

  // this will come in as a base64string and must convert back to arraybuffer
  const response = await generateCompletion({
    message: formattedText,
    voice: voice,
    model: "eleven_multilingual_v2",
    voiceSettings: {
      "stability": 0.5,
      "similarity_boost": 0,
      "style": 0,
      "use_speaker_boost": true
    }
  })

  const base64String = response.data
  const arrayBuffer = Uint8Array.from(atob(base64String), c => c.charCodeAt(0)).buffer

  // Decode the audio data
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Create source node
  const sourceNode = audioContext.createBufferSource();
  sourceNode.buffer = audioBuffer;

  // Create processor node
  const processor = audioContext.createScriptProcessor(1024, 1, 1);

  // Connect the nodes
  sourceNode.connect(processor);
  processor.connect(audioContext.destination);

  // Process audio data
  processor.onaudioprocess = (e) => {
    const inputData = e.inputBuffer.getChannelData(0);
    const outputData = e.outputBuffer.getChannelData(0);

    // Copy input to output (pass-through)
    for (let i = 0; i < inputData.length; i++) {
      outputData[i] = inputData[i];
    }

    // Send the audio data to your talking head system
    registerCallback(inputData);
  };

  // Start playback with delay
  setIsTalking(true)
  const delayInSeconds = 0.1; // Adjust this value as needed
  sourceNode.start(audioContext.currentTime + delayInSeconds);

  // Cleanup when done
  const cleanup = async () => {
		console.log("cleanup")
    await sourceNode.disconnect();
    await processor.disconnect();
    if (audioContext.state !== 'closed') {
      await audioContext.close();
    }
    activeAudioContext = null;
    activeCleanupFunction = null;
    return
  };

  // Store cleanup function
  activeCleanupFunction = cleanup;

  // Update onended handler
  sourceNode.onended = async () => {
    console.log("audio ended");
    await cleanup();
    setIsTalking(false)
  };

  return cleanup;
}









// use this to stream playback
// function playAudioStream(audioRef) {

//   const mediaSource = new MediaSource()

//   const audioContext = new AudioContext();
//   const mediaStreamDestination = audioContext.createMediaStreamDestination()
//   const mediaStreamSource = audioContext.createMediaStreamSource(mediaStreamDestination.stream)

//   const audio = audioRef.current
//   audio.src = URL.createObjectURL(mediaSource)
//   audio.controls = true

//   audio.onended = () => {
//         // audioContext.close()
//         console.log("Audio playback completed")
//     }

//   audio.play()


// 	await new Promise(resolve => mediaSource.addEventListener("sourceopen", resolve))
// 	const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg")


//   const reader = response.body.getReader()
//   const audioChunks = []

//   while (true) {
//     const { done, value } = await reader.read()
//     if (done) {
//       mediaSource.endOfStream()
//       break;
//     }

//     audioChunks.push(value);  // Collect each chunk of audio data

//     await new Promise(resolve => {
//       sourceBuffer.addEventListener("updateend", resolve, { once: true })
//       sourceBuffer.appendBuffer(value)
//     })
//   }

//   // Combine chunks into a buffer
//   const audioBuffer = new Uint8Array(audioChunks.reduce((acc, chunk) => acc + chunk.length, 0));
//   let offset = 0;
//   for (const chunk of audioChunks) {
//       audioBuffer.set(chunk, offset);
//       offset += chunk.length;
//   }

//   // Decode and route to MediaStream
//   audioContext.decodeAudioData(audioBuffer.buffer, (decodedData) => {
//       const sourceNode = audioContext.createBufferSource();
//       sourceNode.buffer = decodedData;
//       sourceNode.connect(mediaStreamDestination);
//       sourceNode.start()
//       console.log("decode complete", audioContext)

//     // if (!audioBlendshapes) {
//     //   runBlendshapesDemo(false)
//     // }
//     const disconnect = streamAudioThroughGraph(audioContext, mediaStreamSource)

//       // todo close the audio stream
//     //   sourceNode.onended = () => {
//     //       audioContext.close();  // Close the context to end the audio stream
//     //   disconnect()
//     //       console.error("close")
//     //   }

//   })
// }