// see: https://codesandbox.io/p/sandbox/rapier-ping-pong-ptdgrn?
import { BackSide } from "three"
import { useGLTF, useTexture } from "@react-three/drei"

export default function Skybox(props) {
  const texture = useTexture(props.bg)

  return (
    <mesh rotation={props.rotation || [0, Math.PI, 0]}>
      <sphereGeometry
        args={[1000, 32, 32]}
      />
      <meshBasicMaterial map={texture} side={BackSide} />
    </mesh>
  )
}
