import MaterialSymbol from "./MaterialSymbol"
import { AnimatePresence, motion } from 'framer-motion'
import { useState, useMemo, useEffect, useCallback } from 'react'
import {isIOS} from './Utils'
import { Slider } from "./ui/slider"
import { Textarea } from "./ui/textarea"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select"

import { models, useCharacter, getImageURL } from "./useCharacter"
import { getVoices } from './Speech'

const centerX = 100
const centerY = 120
const radius = 120

export default function SidePanel({children, className, onClose}) {
  const { updateModel, updateEmotion, setBackstory } = useCharacter()

  const [isOpen, setIsOpen] = useState(false)
  const ios = useMemo(() => isIOS(), [])
  const [sliderValues, setSliderValues] = useState([50, 50, 50, 50, 50])
  const [voices, setVoices] = useState([])
  const [moodColor, setMoodColor] = useState('#FFFFFF')

  useEffect(() => {
    getVoices().then((allVoices) => {
      setVoices(allVoices)
    })
  }, [])

  const pentagonPoints = useMemo(() => {
    const [top, topRight, bottomRight, bottomLeft, topLeft] = sliderValues.map(v => v / 100)

    const points = [
      [centerX, centerY - (radius * top)],
      [centerX + (radius * topRight * Math.cos(Math.PI/10)), centerY - (radius * topRight * Math.sin(Math.PI/10))],
      [centerX + (radius * bottomRight * Math.cos(3*Math.PI/10)), centerY + (radius * bottomRight * Math.sin(3*Math.PI/10))],
      [centerX - (radius * bottomLeft * Math.cos(3*Math.PI/10)), centerY + (radius * bottomLeft * Math.sin(3*Math.PI/10))],
      [centerX - (radius * topLeft * Math.cos(Math.PI/10)), centerY - (radius * topLeft * Math.sin(Math.PI/10))]
    ]

    return {
      points: points.map(([x, y]) => `${x},${y}`).join(' '),
      coordinates: {
        top: points[0][1],
        topRight: points[1],
        bottomRight: points[2],
        bottomLeft: points[3],
        topLeft: points[4]
      }
    }
  }, [sliderValues])

  const staticPentagonPoints = useMemo(() => {
    const points = [
      [centerX, centerY - radius], // Top
      [centerX + (radius * Math.cos(Math.PI/10)), centerY - (radius * Math.sin(Math.PI/10))], // Top Right
      [centerX + (radius * Math.cos(3*Math.PI/10)), centerY + (radius * Math.sin(3*Math.PI/10))], // Bottom Right
      [centerX - (radius * Math.cos(3*Math.PI/10)), centerY + (radius * Math.sin(3*Math.PI/10))], // Bottom Left
      [centerX - (radius * Math.cos(Math.PI/10)), centerY - (radius * Math.sin(Math.PI/10))] // Top Left
    ]

    return points.map(([x, y]) => `${x},${y}`).join(' ')
  }, [])

  const calculateMoodColor = useCallback((values) => {
    // Map slider values to a single mood value
    const [optimistic, funny, calm, alert, confident] = values

    // Calculate average mood (0-100)
    const moodValue = (optimistic + funny + calm + alert + confident) / 5

    // Convert mood to hue (0-360)
    // Lower values (positive mood) = warmer colors (yellow/orange)
    // Higher values (negative mood) = cooler colors (blue/purple)
    const hue = 360 - (moodValue * 3.6) // This maps 0-100 to 360-0

    return `hsla(${hue}, 70%, 50%, 0.6)`
  }, [])

  // Update mood color whenever slider values change
  useEffect(() => {
    updateEmotion(sliderValues)
    const newColor = calculateMoodColor(sliderValues)
    setMoodColor(newColor)
  }, [sliderValues, calculateMoodColor])

  return (
    <motion.div
      className={`absolute h-screen right-0 p-2 z-50 top-0`}
      initial={{
        x: "100%"
      }}
      animate={{
        x: "0%"
      }}
      exit={{
        x: "100%"
      }}
      transition={{type: "spring", stiffness: 400, damping: 24}}
    >
      <div className="overflow-y-auto text-white h-full flex flex-col items-center gap-4 w-80 rounded-md relative bg-white/10 backdrop-blur-lg h-full">
        <h1 className="pt-4 text-lg font-bold">Edit Character</h1>

        <button className="absolute right-0 mt-2" onClick={() => onClose(false)}>
          <MaterialSymbol className="text-white p-2" name={"close"} />
        </button>

        <div className="grid grid-cols-4 gap-2 mx-4">
          {
            models && Object.values(models).map((modelID) => {
              return (
                <button
                  key={`thumb_${modelID}`}
                  className="aspect-square rounded relative overflow-hidden border border-white/40 bg-white/20 hover:bg-white/40"
                  onClick={() => updateModel(modelID)}
                >
                  <img className="size-full object-cover scale-125" src={getImageURL(modelID)} />
                </button>
              )
            })
          }
        </div>
        <div className="flex justify-center items-center">
          <svg
            className="my-2"
            width="300"
            height="200"
            viewBox="0 0 200 220"
          >
            <line
              x1={centerX}
              y1={centerY - radius}
              x2={centerX}
              y2={centerY}
              stroke="white"
              strokeWidth="3"
              strokeDasharray=".1 12"
              strokeLinecap="round"
            />
            <line 
              x1={centerX + (radius * Math.cos(Math.PI/10))}
              y1={centerY - (radius * Math.sin(Math.PI/10))}
              x2={centerX}
              y2={centerY}
              stroke="white"
              strokeWidth="3"
              strokeDasharray=".1 12"
              strokeLinecap="round"
            />
            <line
              x1={centerX + (radius * Math.cos(3*Math.PI/10))}
              y1={centerY + (radius * Math.sin(3*Math.PI/10))}
              x2={centerX}
              y2={centerY}
              stroke="white"
              strokeWidth="3"
              strokeDasharray=".1 12"
              strokeLinecap="round"
            />
            <line
              x1={centerX - (radius * Math.cos(3*Math.PI/10))}
              y1={centerY + (radius * Math.sin(3*Math.PI/10))}
              x2={centerX}
              y2={centerY}
              stroke="white"
              strokeWidth="3"
              strokeDasharray=".1 12"
              strokeLinecap="round"
            />
            <line
              x1={centerX - (radius * Math.cos(Math.PI/10))}
              y1={centerY - (radius * Math.sin(Math.PI/10))}
              x2={centerX}
              y2={centerY}
              stroke="white"
              strokeWidth="3"
              strokeDasharray=".1 12"
              strokeLinecap="round"
            />
            <polygon
              points={staticPentagonPoints}
              fill="none"
              stroke="white"
              strokeWidth="2"
              // strokeDasharray=".1 16"
              strokeLinecap="round"
            />
            <polygon
              points={pentagonPoints.points}
              fill={moodColor}
              // stroke="white"
              // strokeWidth="2"
            />
            <circle
              cx={centerX}
              cy={centerY}
              r="4"
              fill="white"
            />
            {/*<text x="110" y={pentagonPoints.coordinates.top} className="text-xs">{`(100,${Math.round(pentagonPoints.coordinates.top)})`}</text>*/}
            {/*<text x={pentagonPoints.coordinates.topRight[0] + 5} y={pentagonPoints.coordinates.topRight[1]} className="text-xs">{`(${Math.round(pentagonPoints.coordinates.topRight[0])},${Math.round(pentagonPoints.coordinates.topRight[1])}`}</text>*/}
            {/*<text x={pentagonPoints.coordinates.bottomRight[0] + 5} y={pentagonPoints.coordinates.bottomRight[1]} className="text-xs">{`(${Math.round(pentagonPoints.coordinates.bottomRight[0])},${Math.round(pentagonPoints.coordinates.bottomRight[1])}`}</text>*/}
            {/*<text x={pentagonPoints.coordinates.bottomLeft[0] - 45} y={pentagonPoints.coordinates.bottomLeft[1]} className="text-xs">{`(${Math.round(pentagonPoints.coordinates.bottomLeft[0])},${Math.round(pentagonPoints.coordinates.bottomLeft[1])}`}</text>*/}
            {/*<text x={pentagonPoints.coordinates.topLeft[0] - 45} y={pentagonPoints.coordinates.topLeft[1]} className="text-xs">{`(${Math.round(pentagonPoints.coordinates.topLeft[0])},${Math.round(pentagonPoints.coordinates.topLeft[1])}`}</text>*/}
          </svg>
        </div>

        <div className="w-full flex justify-between px-4">
          <Slider
            orientation="vertical"
            defaultValue={[50]}
            start="Optimistic"
            end="Pessimistic"
            min={0}
            max={100}
            step={1}
            onValueChange={(value) => setSliderValues(prev => [value[0], prev[1], prev[2], prev[3], prev[4]])}
            className="h-[120px] flex items-center justify-center rounded w-[4px]"
          />
          <Slider
            orientation="vertical"
            defaultValue={[50]}
            start="Funny"
            end="Serious"
            min={0}
            max={100}
            step={1}
            onValueChange={(value) => setSliderValues(prev => [prev[0], value[0], prev[2], prev[3], prev[4]])}
            className="h-[120px] flex items-center justify-center rounded w-[4px]"
          />
          <Slider
            orientation="vertical"
            defaultValue={[50]}
            start="Calm"
            end="Angry"
            min={0}
            max={100}
            step={1}
            onValueChange={(value) => setSliderValues(prev => [prev[0], prev[1], value[0], prev[3], prev[4]])}
            className="h-[120px] flex items-center justify-center rounded w-[4px]"
          />
          <Slider
            orientation="vertical"
            defaultValue={[50]}
            start="Alert"
            end="Tired"
            min={0}
            max={100}
            step={1}
            onValueChange={(value) => setSliderValues(prev => [prev[0], prev[1], prev[2], value[0], prev[4]])}
            className="h-[120px] flex items-center justify-center rounded w-[4px]"
          />
          <Slider
            orientation="vertical"
            defaultValue={[50]}
            start="Confident"
            end="Insecure"
            min={0}
            max={100}
            step={1}
            onValueChange={(value) => setSliderValues(prev => [prev[0], prev[1], prev[2], prev[3], value[0]])}
            className="h-[120px] flex items-center justify-center rounded w-[4px]"
          />
        </div>

        <div className="w-full px-4 mt-4">
          <Selector data={voices} />
        </div>

        <div className="w-full px-4">
          <Textarea
            className="w-full p-2 focus:border-white focus:outline-none border border-white/40 bg-white/20 hover:bg-white/40 placeholder:text-white/80 rounded-md resize-none h-24"
            placeholder="Backstory..."
            onChange={(value) => setBackstory(value?.target?.value)}
          />
        </div>

        <>
          {children}
        </>
      </div>
    </motion.div>
  )
}


function Selector({data}) {
  const { setVoice } = useCharacter()

  return (
    <>
      {data &&
        <Select
          defaultValue={"pVsngV09koAgEamcRbuK"}
          onValueChange={(val) => {
            setVoice(val)
            // console.log("peter", val)
          }}
        >
          <SelectTrigger className="w-full bg-white/20">
            <SelectValue placeholder="Voice" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Fruits</SelectLabel>
              {data && data.map((voice) => {
                return(
                  <SelectItem
                    value={voice.voice_id}
                    key={`voice_${voice.voice_id}`}
                  >
                    {voice.name}
                  </SelectItem>
                )
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      }
    </>
  )
}