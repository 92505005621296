// started from https://codesandbox.io/p/sandbox/inter-epoxy-resin-lxvqek?
import { useRef, useEffect, useState, useCallback, useTransition } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { useControls, useStoreContext, LevaPanel } from 'leva'

import { Vector3, PointLightHelper, Matrix4, Raycaster, Box3, BoxHelper, GridHelper, ClampToEdgeWrapping, RepeatWrapping, ImageLoader } from "three"
import { EffectComposer, Bloom, Vignette, HueSaturation, BrightnessContrast } from '@react-three/postprocessing'
import { BlendFunction } from 'postprocessing'

import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { MeshLambertMaterial } from "three"

import { generateUID, isIOS, randomNumber } from "./Utils"
import { levaTheme } from "./Themes"
import FigmaSVG from "../figma.svg"
import { toast } from 'sonner'
import Character from './Character'
import { useCharacter } from './useCharacter'

import Skybox from './Skybox'

import {
  CycleRaycast,
  Environment,
  ContactShadows,
  CameraControls,
  RandomizedLight,
  Sky,
  Helper,
  AccumulativeShadows,
  useTexture,
  Cloud,
  Clouds,
  Sparkles,
  CameraShake
} from '@react-three/drei'

import MaterialSymbol from "./MaterialSymbol"
import IconButton from "./IconButton"
import Dropdown from "./Dropdown"
import Sidebar from "./Sidebar"
import SidePanel from "./SidePanel"
import AddPanel from "./AddPanel"


import Gizmo from "./Gizmo"
import { useKeys, KeyCommands, resetInput } from "./Keys"
import InputHelpers, { getFileInput, importFigma } from "./InputHelpers"
import { Exporter } from "./ExportHelpers"


import LayerButton from "./LayerButton"
import { useDraggingSelectionContext, useSelectionContext, useObjectsContext, useCameraContext } from "./SceneContext"

import { Light, Circle, TextField, ObjectCreator, Frame, ImageFrame, Cube, Sphere, GLTF } from "./ObjectCreator"
import { Grid, AvatarCreator } from "./AvatarCreator"

import { useSearchParams } from "react-router-dom"

// const { getState, setState, subscribe, destroy } = store

// const useSceneGraph = create((set) => ({
//   sceneGraph: {},
//   setSceneGraph: (sceneGraph) => set({sceneGraph}),
//   removeSceneGraph: () => set({}),
// }))

// https://github.com/pmndrs/react-three-fiber/issues/1249



export default function Scene(props) {
  // contexts
  const sceneStore = useStoreContext()
  const [transformInput, setTransformInput] = useCameraContext()
  const [objects, setObjects] = useObjectsContext()
  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()

  // states
  const [isExporting, setIsExporting] = useState(false)
  const [sceneGraph, setSceneGraph] = useState()
  const [hoveredSelection, setHoveredSelection] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [showAddPanel, setShowAddPanel] = useState(false)

  // refs
  const objectsRef = useRef(null)
  const cameraRef = useRef(null)
  const previewRef = useRef(null)
  const draggingRef = useRef(false)
  const characterRef = useRef(null)


  const { position, isEditing, setIsEditing, isExiting, setIsExiting } = useCharacter()


  const [searchParams, setSearchParams] = useSearchParams()

  // useEffect(() => {
  //   console.log("drag", isDragging)
  // }, [isDragging])

  const loopCamera = useCallback(async () => {
    if (!cameraRef.current) return

    console.log('start loop camera', "edit:", isEditing, "exit:", isExiting)

    if (isEditing) {
      cameraRef.current.cancel() // cancel ongoing camera shit
      console.log("editing so zoom out reset", cameraRef.current)
      cameraRef.current.cancel()
      const x = randomNumber(Math.PI/3, Math.PI/4.5)
      const y = randomNumber(Math.PI/2, Math.PI/2.5)
      cameraRef.current.smoothTime = .5
      // cameraRef.current.restThreshold = 1

      cameraRef.current.setTarget(0, 0, 0, true)
      cameraRef.current.rotateTo(Math.PI/4, Math.PI/3, true)
      cameraRef.current.dollyTo(500, true)
      // await cameraRef.current.rotateTo(x, y, true)
      return
    } else {
      // if previously was editing, lets exit the mode
      console.log("not-editing, zoom in", isEditing, isExiting)
      if (isExiting) {
        cameraRef.current.cancel() // cancel ongoing camera shit
        cameraRef.current.smoothTime = .5
        cameraRef.current.restThreshold = .5

        // cameraRef.current.dollyTo(500, true)

        // cameraRef.current.setTarget(-1.5, offset, 0, true)
        // console.log("char", characterRef.current.position)
        const size = 10
        const offset = new Vector3(-8, 60, 0)
        const box = new Box3(characterRef.current.position.clone().subScalar(size).add(offset), characterRef.current.position.clone().addScalar(size).add(offset))

        cameraRef.current.setTarget(characterRef.current.position.x, characterRef.current.position.y+60, characterRef.current.position.z, true)

        await cameraRef.current.fitToBox(box, true, {
          paddingTop: 1,
          paddingright: 1,
          paddingBottom: 1,
          paddingLeft: 1,
        })

        setIsExiting(false)
      }
    }

    // console.log("loop cam", draggingRef.current, isDragging, cameraRef.current._hasRested)
    // cameraRef.current.smoothTime = 1

    const x = randomNumber(Math.PI/3, Math.PI/4.5)
    const y = randomNumber(Math.PI/2, Math.PI/2.5)
    // console.log("get it", x, y)

    cameraRef.current.restThreshold = 0.1
    if (!draggingRef.current) {
      // console.log("not dragging", isDragging)

      cameraRef.current.smoothTime = 10

      await cameraRef.current.rotateTo(x, y, true)
      console.log("looping camera after await", isEditing, isExiting)
      if (!isEditing && !isExiting) {
        setTimeout(loopCamera, 100)  // Adjust the delay as needed
      }
    } else {
      // console.log("still dragging", isDragging)
      cameraRef.current.smoothTime = .25
      cameraRef.current.draggingSmoothTime = .05
      cameraRef.current.restThreshold = 0.1

      // cameraRef.current.smoothTime = .25
      // cameraRef.current.restThreshold = 0.1
    }
  }, [isEditing, isExiting])


  const init = useCallback(async (camera) => {
    // this is mount
    // console.log('mount', cameraRef?.current)
    // console.log("cam ref", camera.current, cameraRef.current)
    if (cameraRef.current) {
      console.log('mount', cameraRef?.current)
      const offset = characterRef.current.position.clone().add(new Vector3(-8, 60, 0))
      cameraRef.current.smoothTime = .75
      cameraRef.current.restThreshold = 1

      await cameraRef.current.dollyTo(500, false)
      await cameraRef.current.rotateTo(0, 0, false)
      await cameraRef.current.setTarget(0, 0, 0, false)

      cameraRef.current.setTarget(offset.x, offset.y, offset.z, true)
      cameraRef.current.dollyTo(50, true)
      await cameraRef.current.rotateTo(Math.PI/4, Math.PI/2.5, true)

      console.log("init loop camera")
      loopCamera()
    }
  }, [cameraRef, characterRef])

  // this will do camera mount init
  useEffect(() => {
    // console.log('mount', cameraRef?.current)
    init()


    // cameraRef?.current.addEventListener('wake', handleCameraEnd)
    // cameraRef?.current.addEventListener('rest', handleCameraEnd)
    // cameraRef?.current.addEventListener('sleep', handleCameraEnd)

    // return () => {
    //   cameraRef?.current.removeEventListener('wake', handleCameraEnd)
    //   cameraRef?.current.removeEventListener('rest', handleCameraEnd)
    //   cameraRef?.current.removeEventListener('sleep', handleCameraEnd)
    // }


  }, [cameraRef.current])

  useEffect(() => {
    console.log("isEditing change, loop camera", isEditing, isExiting)

    loopCamera()
  }, [isEditing, isExiting])

  // const handleCameraEnd = useCallback((e) => {
  //   console.log("end camera", e)
  // }, [])

  const handleTransformInput = useCallback((e) => {
    // const camera = cameraRef()
    const rotation = e.target._spherical
    if (rotation.phi < Math.PI/3) {
      // console.log("xz only, disable y")
      setTransformInput([true, false, true])
    } else {
      if (Math.abs(rotation.theta % Math.PI) < Math.PI/3 || Math.abs(rotation.theta % Math.PI) > Math.PI*2/3) {
        // console.log("xy only, disable z")
        setTransformInput([true, true, false])
      } else {
        // console.log("yz only, disable x")
        setTransformInput([false, true, true])
      }
    }
  }, [setTransformInput])

  const handleTransformStartInput = useCallback((e) => {
    // console.log("start drag e", e.target._hasRested, e.target)
    // cameraRef.current.cancel()
    // cameraRef.current.smoothTime = 0.1

    setIsDragging(true)
    draggingRef.current = true

    // cameraRef.current.cancel()
    cameraRef.current.smoothTime = .25
    cameraRef.current.draggingSmoothTime = .05
    cameraRef.current.restThreshold = 0.1
  }, [setIsDragging])


  const handleTransformEndInput = useCallback((e) => {
    console.log("end drag", draggingRef.current, e)
    setIsDragging(false)
    draggingRef.current = false

    // cameraRef.current.smoothTime = .25
    // cameraRef.current.draggingSmoothTime = .05
    // cameraRef.current.restThreshold = 0.1

    if (!isEditing) {
      console.log("transform end, loop camera")
      loopCamera()
    }
  }, [setIsDragging, isEditing, isExiting])


  // const handleTransformRestInput = useCallback((e) => {
  //   console.log("rest", e)
  //   loopCamera()
  // }, [])

  // fit/frame selection
  const fitSelection = useCallback(async() => {
    console.log("fit")
    // const camera = cameraRef()
    if (cameraRef.current && objects[selection]) {
      // console.log("get", objects[selection])
      const target = objects[selection]?.ref ? Object.values(objects[selection].ref.position) : [0, 0, 0]
      const targetOffset = Object.values(new Vector3(...target).add(new Vector3(0, 0, 0)))
      // const target = [0, 0, 0]
      // console.log("frame", objects[selection], target)
      // await cameraRef.current.moveTo(...targetOffset, true) //this one works well if the orbit point isn't set
      console.log("select", objects[selection]?.ref)
      await cameraRef.current.fitToBox(objects[selection]?.ref, true, {
        paddingTop: 1,
        paddingright: 1,
        paddingBottom: 1,
        paddingLeft: 1,
      })
    }
  }, [selection])


  // useEffect(() => {
  //   if (!selection) {
  //     console.log('reset input')
  //     resetInput()
  //   }
  // }, [selection])

  const createObject = useCallback((props) => {
    const uid = generateUID()

    const object = {
      ...props,
      uid: uid
    }

    setObjects(oldObjects => {
      return (
        {
          ...oldObjects,
          [uid]: object,
        })
    })
  }, [setObjects, generateUID])


  useEffect(() => {
    // console.log('use effect', sceneGraph?.children)
  }, [sceneGraph])

  // check if it's a figma link and get the nodes
  useEffect(() => {
    // const page = searchParams.get("page")
    // const node = searchParams.get("node")
    console.log('use effect mount')

    // createObject({
    //   type: "gltf",
    //   src: "gltf/rpm_dude.glb",
    //   position: [0, -1, -1],
    //   rotation: [0, -Math.PI/6, 0],
    //   size: [3, 3, 3]
    // })

    // createObject({
    //   type: "gltf",
    //   key: `character`,
    //   i: 1,
    //   position: [0, 0, 0],
    //   rotation: [0, 0, 0],
    //   size: [10, 10, 10],
    //   src: "gltf/Fem-A_Tia_Body_and_hair.glb",
    //   // uid={'bg'}
    //   name: "character"
    // })

    // {/*city*/}
    // createObject({
    //   type: "gltf",
    //   key:`bg`,
    //   i:1,
    //   position:[0, 0, 0],
    //   rotation:[0, 0, 0],
    //   size:[.02, .02, .02],
    //   src:"skybox/city_skyline.glb",
    //   // uid={'bg'}
    //   name: "bg",
    // })


  }, [])


  return (
    <>
      <div className="z-40 text-white absolute top-0 right-0 pointer-events-none p-4">
        <AnimatePresence>
          {!showSidePanel && !isEditing &&
            <motion.div
              initial={{scale: 0}}
              animate={{scale: 1}}
              exit={{scale: 0}}
            >
              <IconButton
                name="add"
                icon={"add"}
                // isActive={isDrawing === "text"}
                onClick={() => {
                  setShowAddPanel(true)
                  setIsEditing(true)
                }}
              />
              <IconButton
                name="edit"
                icon={"tune"}
                // isActive={isDrawing === "text"}
                onClick={() => setShowSidePanel(!showSidePanel)}
              />
            </motion.div>
          }
        </AnimatePresence>
      </div>

      <div className="z-40 text-white absolute bottom-0 left-0 pointer-events-none p-4">
        <IconButton
          name="audio"
          icon={isMuted ? "volume_off" : "volume_up"}
          // isActive={isDrawing === "text"}
          onClick={() => setIsMuted(!isMuted)}
        />
      </div>

      <div className="text-sm text-center w-full text-white flex z-40 absolute bottom-0 w-full pb-4 px-4 justify-center items-center pointer-events-none">

        {isIOS() ?
          <>
          </>
        :
          <>
            <span className="opacity-40">drag to orbit, r-click/shift to pan, pinch/ctrl to zoom</span>
            <IconButton
              className="absolute bottom-0 right-0 m-2"
              name="Fit Selection"
              icon="filter_center_focus"
              // isActive={}
              onClick={fitSelection}
            />
          </>
        }

      </div>

      {objects && Object.keys(objects).length !== 0 &&
        <div className="p-2 sm:p-4 absolute z-40 max-h-dvh overflox-x-auto overflow-y-auto">
          {/*<MaterialSymbol className="text-white backdrop-blur" name={"menu"} />*/}
          <motion.div layoutScroll className={"rounded-md min-w-24 max-w-48 backdrop-blur-lg bg-white/10 text-white flex flex-col px-4 py-4"}>
            <AnimatePresence>
              {Object.entries(objects).map(([key, val], i) => {
                return (
                  <LayerButton
                    key={`layer_${key}`}

                    name={`${val.name || val.type} ${i+1}`}
                    selected={selection === key}

                    onClick={() => {
                      // set selection but find store somehow within
                      setSelection(key)
                    }}

                    onDelete={() => {
                      setObjects((prevData) => {
                        const newData = {...prevData}
                        delete newData[key]
                        return newData
                      })

                      setSelection(null)
                    }}
                  />
              )})}
            </AnimatePresence>
          </motion.div>
        </div>
      }
      <AnimatePresence>
        {showAddPanel && isEditing &&
          <AddPanel
            onClose={setShowAddPanel}
          />
        }

        {showSidePanel &&
          <SidePanel
            onClose={setShowSidePanel}
          />
        }

        {!showSidePanel && !isEditing &&
          <motion.div
            initial={{scale: 0}}
            animate={{scale: 1}}
            exit={{scale: 0}}
          >
            <Sidebar>
              <div className="absolute z-20 p-2 sm:p-4 max-h-dvh overflow-y-auto right-0 top-0">
                <div className="overflow-hidden min-w-24 w-64 rounded-md h-fit bg-white/10 backdrop-blur-lg pt-px"
                >
                  <LevaPanel
                    key="levaScene"
                    store={sceneStore}
                    theme={levaTheme}
                    titleBar={false}
                    drag={true}
                    hideCopyButton={true}
                    flat
                    fill
                    // collapsed
                  />

                  {objects[selection]?.store &&
                    <div className="">
                      <div className="bg-white/10 pt-1 pb-1 px-2">
                        <hr className="opacity-50" />
                      </div>
                      <LevaPanel
                        key="levaObject"
                        store={objects[selection]?.store}
                        titleBar={false}
                        theme={levaTheme}
                        fill
                        flat
                      />
                    </div>
                  }
                </div>

              </div>
            </Sidebar>
          </motion.div>
        }

      </AnimatePresence>

      {/* music */}

      {!isMuted &&
        <iframe
          className="absolute"
          width="1"
          height="1"
          wmode="transparent"
          src="//www.youtube.com/embed/U4XTPXVVNFU?autoplay=1&loop=1&start=18&playlist=U4XTPXVVNFU"
          frameBorder="0"
          // allowfullscreen
        />
      }

      {/*helps with input*/}
      <InputHelpers createObject={createObject} />

      <Canvas
        // colorManagement={false}
        // linear flat
        // className={isDragging ? "cursor-grabbing" : "cursor-grab"}
        className={hoveredSelection.length ? "cursor-pointer" : isDragging ? "cursor-grabbing" : "cursor-grab"}
        // onCreated={({ set, get }) => {
        //   setSceneGraph({ set, get })
        //   // init(cameraRef)
        // }}
        onCreated={(state) => setSceneGraph(state.get().scene)}
        shadows
        // orthographic
        // camera={{ position: [200, 200, 500], fov: 55, zoom: 80, far: 10000 }}
        camera={{ position: [20, 20, 50], fov: 35, far: 10000, near: .1 }}

        // antialias={"true"}
        // alpha={transparent ? "true" : "false"}
        // powerPreference={"high-performance"}

        gl={{ preserveDrawingBuffer: true }}
        dpr={[1, 2]}
        // onPointerUp={(e) => {
        //   // console.log("you clicked", e)
        // }}
        // onPointerMissed={() => setTarget(null)}
        onPointerMissed={(e) => {
          console.log("pointer miss, clear selection")
          setSelection(null)

          // setTarget(null)
          resetInput()
        }}
      >

        <group ref={objectsRef}>

{/*          <Clouds
            limit={400}
            material={MeshLambertMaterial}
          >

            <Cloud
              position={[5, 0, 0]}
              rotation={[0, Math.PI/2, 0]}
              seed={1}
              fade={30}
              speed={0.1}
              growth={4}
              segments={40}
              volume={6}
              opacity={.2}
              bounds={[20, 3, 4]}
              color={"white"}
            />

            <Cloud
              position={[-5, 10, 10]}
              rotation={[0, Math.PI/2, 0]}
              seed={1}
              scale={5}
              fade={30}
              speed={0.1}
              growth={4}
              segments={40}
              volume={6}
              opacity={.2}
              bounds={[8, 3, 4]}
              color={"white"}
            />

            <Cloud
              position={[-10, 5, -10]}
              rotation={[0, Math.PI/2, 0]}
              seed={10}
              fade={30}
              speed={0.1}
              growth={4}
              segments={40}
              volume={6}
              opacity={.2}
              bounds={[5, 3, 1]}
              color={"pink"}
            />
          </Clouds>
*/}


        {!isEditing &&
          <Sparkles
            renderOrder={10}
            position={[0, 5, 0]}
            count={40}
            scale={100}
            size={120}
            speed={.8}
          />
        }


          {objects &&
            Object.entries(objects).map(([key, val], i) => {
              return (
                <>
                  { val.type === "gltf" ?
                    <GLTF
                      key={`${val.type}_${key}`}
                      i={i}
                      uid={key}
                      name={val.name}
                      type={val.type}
                      position={val.position}
                      rotation={val.rotation}
                      size={val.size}

                      // hovered={hoveredSelection?.[0]?.object?.uid === key}
                      // selected={selection === key}
                      // draggable={isDraggable(key)}
                      src={val.src}
                    />
                  :
                    null
                  }
                </>
              )
            })
          }

          <AvatarCreator />

          <Character
            ref={characterRef}
            scale={40}
            rotation={[0, Math.PI/6, 0]}
            position={position || [0, -56, 0]}
            // onClick={() => setShowSidePanel(true)}
            // onPointerOver{() => console.log("hihihi")}
          />

          {!isEditing &&
            <Skybox
              bg={`/skybox/autumn_forest.jpg`}
            />
          }

          {/*clouds*/}
{/*          <GLTF
            key={`bg`}
            i={1}
            // uid={'bg'}
            // name={"bg"}
            position={[0, 0, 0]}
            rotation={[0, 0, 0]}
            size={[.02, .02, .02]}
            src={"skybox/anime_sky.glb"}
          />

*/}


{/*          <Sphere
            key={`bg`}
            i={2}

            position={[0, 0, 0]}
            rotation={[0, 0, 0]}
            size={50}
            src={"images/where_i_escape.jpg"}
            draggable={false}
          />
*/}


{/*          <ImageFrame
            key={`bg`}
            i={0}

            position={[0, 0, -100]}
            rotation={[0, 0, 0]}
            size={[100, 100, .1]}
            src={"images/where_i_escape.jpg"}
          />*/}




{/*          <GLTF
            key={`bg`}
            i={1}
            // uid={'bg'}
            // name={"bg"}
            position={[0, 0, 0]}
            rotation={[0, 0, 0]}
            size={[10, 10, 10]}
            src={"gltf/shibahu.glb"}
          />*/}



        {/*
          <TextField
            key={`${val.type}_${key}`}
            i={i}
            uid={key}
            name="Text"
            position={val.position}
            rotation={val.rotation}
            size={val.size}

            type={"text"}
            depth={val.depth}
            font={val.font}
            text={val.text}

            hovered={hoveredSelection?.[0]?.object?.uid === key}
            selected={selection === key}
            draggable={isDraggable(key)}
          />

          <TextField
            key={`${val.type}_${key}`}
            i={i}
            uid={key}
            name="Icon"
            position={val.position}
            rotation={val.rotation}
            size={val.size}

            type={"icon"}
            depth={val.depth}
            font={val.font}
            text={val.text}

            hovered={hoveredSelection?.[0]?.object?.uid === key}
            selected={selection === key}
            draggable={isDraggable(key)}
          />

          <Sphere
            key={`${val.type}_${key}`}
            i={i}
            uid={key}
            name={val.type}
            type={val.type}

            position={val.position}
            rotation={val.rotation}
            size={val.size}
            src={val.src}

            hovered={hoveredSelection?.[0]?.object?.uid === key}
            selected={selection === key}
            draggable={isDraggable(key)}
          />


          <ImageFrame
            key={`${val.type}_${key}`}
            i={i}
            uid={key}
            name={val.name}
            type={val.type}
            size={val.size}
            position={val.position}
            color={val.color}

            hovered={hoveredSelection?.[0]?.object?.uid === key}
            selected={selection === key}
            draggable={isDraggable(key)}

            src={val.src}
          />

          <Light
            key={`${val.type}_${key}`}
            i={i}
            uid={key}
            name={val.type}
            type={val.type}

            hovered={hoveredSelection?.[0]?.object?.uid === key}
            selected={selection === key}
            draggable={isDraggable(key)}
          />
        */}

        </group>



        {/** Controls */}
        <KeyCommands
          cameraRef={cameraRef}
          fitSelection={fitSelection}
          // setIsDrawing={setIsDrawing}
          createObject={createObject}
        />

        <CameraControls
          ref={cameraRef}
          dollyToCursor={true}
          // enabled={isDrawing ? false : true}
          makeDefault
          onChange={handleTransformInput}
          onStart={handleTransformStartInput}
          onEnd={handleTransformEndInput}
          draggingSmoothTime={.05}

          // rest={handleTransformRestInput}
          // sleep={handleTransformRestInput}
          // wake={handleTransformRestInput}
          // events={true}

          // autoRotate={autoRotate}
          // autoRotateSpeed={-0.1}
          // zoomSpeed={0.25}
          // minZoom={40}
          // maxZoom={140}
          // // enablePan={false}
          // // enableDamping={true}
          // smoothTime={0}
          // minPolarAngle={Math.PI * .05}
          // maxPolarAngle={Math.PI / 1.95}
        />

{/*
        <CameraShake
          maxYaw={0.01} // Max amount camera can yaw in either direction
          maxPitch={0.01} // Max amount camera can pitch in either direction
          maxRoll={0.01} // Max amount camera can roll in either direction
          controls={cameraRef} // if using orbit controls, pass a ref here so we can update the rotation
        />
*/}
        <Env />

        <Gizmo
          cameraRef={cameraRef}
        />
        {/*<CycleRaycast onChanged={(objects, cycle) => set({ objects, cycle })} />*/}
        <CycleRaycast
          preventDefault={true} // Call event.preventDefault() (default: true)
          scroll={true} // Wheel events (default: true)
          keyCode={null} // Keyboard events (default: 9 [Tab])
          onChanged={(intersectingObjects, cycle) => {
            // console.log("inter", intersectingObjects?.[0]?.object?.uid, intersectingObjects)
            setHoveredSelection(intersectingObjects)
          }}
        />

        {isExporting &&
          <Exporter
            objects={objectsRef}
            type={isExporting}
            setIsExporting={setIsExporting}
            previewRef={previewRef}
          />
        }
      </Canvas>
    </>
  )
}

function Env() {
  const {isEditing} = useCharacter()
  const sceneStore = useStoreContext()
  const [preset, setPreset] = useState('city')

  // You can use the "inTransition" boolean to react to the loading in-between state,
  // For instance by showing a message
  const [inTransition, startTransition] = useTransition()

  const { grid, bloom, threshold, transparent, radius, ambient, background, shadow, position, backgroundBlurriness } = useControls("Scene", {
    position: {value: [0, 10, -10]},
    shadow: { value: '#cccccc' },
    radius: { value: 10, min: 0, max: 20 },
    ambient: { value: 0.5, min: 0, max: 1 },
    bloom: { value: 2, min: 0, max: 10 },
    threshold: { value: 1, min: 0, max: 2 },
    background: { value: '#bbb' },
    transparent: {value: false},
    grid: {value: true},
    backgroundBlurriness: { value: 0.65, min: 0, max: 1 },
    preset: {
      value: preset,
      options: ['sunset', 'dawn', 'night', 'warehouse', 'forest', 'apartment', 'studio', 'city', 'park', 'lobby'],
      // If onChange is present the value will not be reactive, see https://github.com/pmndrs/leva/blob/main/docs/advanced/controlled-inputs.md#onchange
      // Instead we transition the preset value, which will prevents the suspense bound from triggering its fallback
      // That way we can hang onto the current environment until the new one has finished loading ...
      onChange: (value) => startTransition(() => setPreset(value))
    }
    // outlines: {value: true}
  },
    { collapsed: true },
    { store: sceneStore }
  )


  return (
    <>

      {/** Soft shadows */}

      {/*<AccumulativeShadows resolution={1024} blend={100} frames={100}  limit={isDrawing ? 1 : 100}  color={color} colorBlend={1.5} toneMapped={true} alphaTest={0.68} opacity={1} scale={100} position={[0, 0, 0]}>
        <RandomizedLight radius={radius} ambient={ambient} size={15} mapSize={1024} bias={0.001} position={position} />
      </AccumulativeShadows>*/}


      { transparent &&
        <color attach="background" args={[background]} />
      }

      {/** the grid */}
      { grid && isEditing &&
        <Grid />
      }

{/*      <AccumulativeShadows
        temporal
        frames={100}
        color={shadow}
        colorBlend={2}
        toneMapped={true}
        alphaTest={0.7}
        opacity={1}
        scale={12}
        position={[0, 0, 0]}
      >
        <RandomizedLight amount={8} radius={radius} ambient={ambient} position={[5, 5, -10]} bias={0.001} />
      </AccumulativeShadows>
*/}

      {/*really nice shadows on the bottom*/}
      {/*<ContactShadows resolution={1024} position={[0, 0, 0]} opacity={1} scale={radius*2} blur={1} far={0.8} />*/}

      <ambientLight intensity={ambient * Math.PI} />

      <spotLight decay={0} position={[10, 5, -10]} angle={0.15} penumbra={1}>
        {/*<Helper type={PointLightHelper} />*/}
      </spotLight>

      <pointLight decay={0} position={[-10, -10, -10]}>
        {/*<Helper type={PointLightHelper} />*/}
      </pointLight>

      <spotLight decay={0} position={[10, -5, 10]} angle={0.15} penumbra={1}>
        {/*<Helper type={PointLightHelper} />*/}
      </spotLight>

      <pointLight decay={0} position={[-10, 10, 10]}>
        {/*<Helper type={PointLightHelper} />*/}
      </pointLight>


      {/* see: https://codesandbox.io/p/sandbox/environment-blur-and-transitions-pj7zjq? */}
      <Environment
        preset={preset}

        background={transparent ? false : true}
        backgroundBlurriness={backgroundBlurriness} // optional blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
        // backgroundIntensity={1} // optional intensity factor (default: 1, only works with three 0.163 and up)
        // environmentIntensity={1} // optional intensity factor (default: 1, only works with three 0.163 and up)
      />

      {/*<Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />*/}

      {/* sky */}
      {/*<Sky />*/}


      <EffectComposer
        // depthBuffer={true}
        // autoClear={true}
        renderPriority={1000}
        stencilBuffer={true}
      >
        <Bloom
          luminanceThreshold={threshold}
          intensity={bloom}
          luminanceSmoothing={0.9}
          levels={9}
          mipmapBlur
        />

{/*
        <Vignette
          eskil={false}
          offset={0.1}
          darkness={1.1}
        />
*/}
      </EffectComposer>


{/*
      <Environment resolution={32}>
        <group rotation={[-Math.PI / 4, -0.3, 0]}>
          <Lightformer intensity={20} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[10, 2, 1]} />
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[10, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[20, 2, 1]} />
          <Lightformer type="ring" intensity={2} rotation-y={Math.PI / 2} position={[-0.1, -1, -5]} scale={10} />
        </group>
      </Environment>
*/}
    </>
  )
}

