import { Toaster } from 'sonner'
import Scene from './components/Scene'
import Chat from './components/Chat'
import SceneProvider from './components/SceneProvider'

// export async function loader({ params }) {

export default function Root() {
  return (
    <div className="w-screen h-dvh relative">

      <div id="modal"
        className="items-center fixed z-50 size-full flex items-center justify-center pointer-events-none"
      />

      <Toaster
        position="bottom-center"
        toastOptions={{
          className: 'rounded-md bg-white/10 backdrop-blur-sm border-none text-white text-center justify-center',
        }}
      />

      <Chat />

      <SceneProvider>
        <Scene />
      </SceneProvider>
    </div>
  )
}