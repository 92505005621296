// started from https://codesandbox.io/p/sandbox/inter-epoxy-resin-lxvqek?
import { useRef, useEffect, useState, useCallback, useTransition } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { generateSpeech } from './Speech'

import { generateUID, isIOS, randomNumber, numToArray, randomChoice, cleanJSON } from "./Utils"

import MaterialSymbol from "./MaterialSymbol"
import IconButton from "./IconButton"
import Dropdown from "./Dropdown"
import Portal from "./Portal"
import { useChat } from "./useChat"
import { useCharacter } from "./useCharacter"

const size = 4
const padding = 3
const height = 12

const dotVariants = {

  rest: () => {
    return {
      width: size,
      height: size,
      marginLeft: padding,
      marginRight: padding,
      x: 0,
      y: 0,
    }
  },

  bounce: (i) => {
    const length = 3
    const duration = .6
    const offset = .1
    const delay = .25 + ((length-1) * offset) - (i * offset)

    return {
      width: size,
      height: size,
      // marginRight: 4,
      x: i === 2 ? [0, (size + (padding*2)) * -(length-1)] : [0, (size + (padding*2))],
      y: i === 2 ? [0, height, 0] : [0, -height, 0],
      scale: 1,
      transition: {
        // staggerChildren: 0.5,
        // when: "beforeChildren", //use this instead of delay
        x: {ease: [.4, 0, .4, 1], duration: duration, delay: delay},
        y: {ease: [.4, 0, .4, 1], duration: duration, delay: delay},
        // scale: {ease: [.4, 0, .2, 1], duration: .5, repeatDelay: i * .5/3, repeat: Infinity, repeatType: "mirror"}
      },
      // transitionEnd: {x: 0, y: 0}
    }
  },
}


export default function Chat(props) {
  // states
  const {
    getReply,
    chats,
    setChats,
    level,
    setLevel,
    score,
    setScore,
    mood,
    setMood
  } = useChat()

  const { backstory, emotion } = useCharacter()

  const [isMuted, setIsMuted] = useState(false)
  const [upsell, setUpsell] = useState(false)

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    // console.warn("scroll to bottom")
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  // for each time user writes a message, get a reply from AI
  useEffect(() => {
    const lastMessage = chats[chats.length-1]
    if (lastMessage && lastMessage?.role === "user") {
      console.log("last", lastMessage, chats)
      getReply({chats, backstory, emotion})
    }

    scrollToBottom()
  }, [chats])

  useEffect(() => {
    if (level > 1) {
      setUpsell(true)
    }

  }, [level])

  return (
    <div className="">


{/*    {upsell &&
      <Portal selector="#modal">
        <div className="flex flex-col gap-8 items-center p-16 text-white pointer-events-auto rounded-xl w-[32rem] relative overflow-auto max-h-[80vh] shadow-2xl bg-gradient-to-b from-orange-500 to-purple-500">
          <img className="rounded-full w-96 h-96 object-cover" src="images/3978531179-1315543663.png" />

          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-bold">New chapter of your relationship is here</h2>
            <p className="">
              Start a romantic adventure. Get even closer with videocalls. Power up chat with advanced AI.
            </p>
          </div>

          <div className="flex gap-16">
            <button
              className="px-12 py-4 rounded-full bg-black text-white fit-content font-bold"
              onClick={() => setUpsell(false)}
            >
              Unlock Now
            </button>
          </div>
        </div>
      </Portal>
    }
*/}

      <div className="start-[12%] absolute flex flex-col mt-8 left-0 z-50 items-center gap-8">
        <RelationshipBar
          score={score}
          level={level}
          mood={mood}
        />

        <div className="flex flex-col text-sm text-white">
{/*          <button
            onClick={() => {setLevel(2)}}
          >
            nsfw
          </button>

          <button
            onClick={() => {
              setChats(oldChats => {
                return (
                  [
                    ...oldChats,
                    {content: "", role: "assistant", image: true},
                  ])
              })
            }}
          >
            send pic
          </button>*/}

        </div>
      </div>

      <div
        className="absolute z-40 px-2 sm:px-4 bottom-0 start-[12%] h-full max-h-dvh flex flex-col items-start justify-end pointer-events-none"
        style={{
          maskImage: "linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%)"
        }}
      >
        <div className="h-fit max-w-xl overflox-x-hidden overflow-y-auto pointer-events-auto">
          <div className="h-24 pointer-events-none" />

          {chats && Object.keys(chats).length !== 0 &&
            <motion.div
              layoutScroll
              className={"text-white leading-tight flex flex-col gap-2 max-w-full md:w-96"}

            >
              <AnimatePresence>
                {Object.values(chats).map((val, i) => {
                  return (
                    <ChatBubble
                      key={`chat_${i}`}
                      content={val.content}
                      role={val.role}
                      image={val.image}
                      scrollToBottom={scrollToBottom}
                      onClick={() => {
                        // set selection but find store somehow within
                      }}

                    />
                )})}
              </AnimatePresence>
            </motion.div>
          }

          <div className="h-32" />
          <div ref={messagesEndRef} />
        </div>

      </div>

      <div
        className="absolute z-40 px-2 sm:px-4 bottom-0 start-[12%] pb-16"
      >
        <InputBubble
          setChats={setChats}
        />
      </div>
    </div>
  )
}

function RelationshipBar({level, score, mood}) {
  // const level = Math.round(progress / 100)
  // console.log("mod ", level, score)

  return (
    <div className="flex items-center gap-4">
      <div className="relative flex flex-col items-center justify-center w-48 h-12 overflow-hidden rounded-full bg-pink-700 shadow-2xl">
        <motion.div className="absolute top-0 left-0 h-full bg-pink-500"
          initial={{width: 0}}
          animate={{width: `${score * 100}%`}}
        />
        <div className="text-white z-40 top-0">LVL {level}</div>
        <div className="text-xs text-white z-40 -mt-1 opacity-60 italic">{mood} {score}</div>
      </div>
    </div>
  )

}

function InputBubble({name, setChats, icon, selected=false, onClick, onDelete}) {
  const inputRef = useRef()

  const handleKeyDown = useCallback(e => {
    if (e.key === 'Enter' || e === "submit") {
      // console.log('do validate', inputRef.current.value)
      const newChat = {
        role: "user",
        content: inputRef.current.value
      }

      inputRef.current.value = ""

      setChats(oldChats => {
        return (
          [
            ...oldChats,
            newChat,
          ])
      })
    }
  })

  return (
    <motion.div
      className="flex gap-2 mt-4 rounded-md min-w-24 backdrop-blur-lg bg-white/10 w-fit"
      style={{ originX: 0, originY: 0 }}
      initial={{scale: .5, opacity: 0}}
      animate={{scale: 1, opacity: 1}}
      exit={{scale: 0, opacity: 0}}
      transition={{type: "spring", stiffness: 400, damping: 24}}
    >
      <input
        ref={inputRef}
        // id={"inputTextHelper"}
        placeholder={"send a text..."}
        // className="pointer-events-none text-black opacity-0 absolute"
        className={`placeholder:text-white/60 px-4 py-2 bg-transparent border-transparent border hover:border-white/80 focus:ring-1 hover:ring-white/80 focus:ring-white outline-none rounded ${selected ? "text-white opacity-100" : "text-white opacity-40"} hover:opacity-80 focus:opacity-100 text-left overflow-hidden`}
        type="text"
        autoComplete="off"
        onKeyDown={handleKeyDown}
      />
      {isIOS() &&
        <button className="absolute right-0"
          onClick={() => handleKeyDown("submit")}
        >
          <MaterialSymbol className="text-white p-2" name={"send"} />
        </button>
      }

    </motion.div>
  )
}


function ChatBubble({role, content, image, i, icon, selected=false, onClick, onDelete, scrollToBottom}) {
  const [isLoading, setIsLoading] = useState(true)
  const [loop, setLoop] = useState(true)
  const [randomImage, setRandomImage] = useState(null)
  const { mood } = useChat()
  const { emotion, voice } = useCharacter()

  useEffect(() => {
    // setSpeechIsReady(false)

    if (role === "assistant") {
      console.log("generate speech", voice, mood)

      generateSpeech({
        message: content,
        voice: voice,
        mood: mood || "flirty"
      }).then(() => {
        // console.log("png speech", content)
        setIsLoading(false)
      })
    }

  }, [content, voice])

  useEffect(() => {
    setRandomImage(randomChoice([
      "D7AC4AB6F2A03418C0AAC49C4A10E9ABAD0E591BFCBE2D834BF1A3A7B82E7EB5.jpeg",
      "0EC6AB98EC6C984BBBF88AE1AECBCCCFB718813A74AADF10A88AE96F472E3C30.jpg",
      "A2453C853857E203EC08D3E3C707FA6867B095D048E9DBA67B49528F1BD35072.jpg",
      "EA304778E39138A1A6A0217670DED5B97BB999E2CB658AE3C4E9FCD5F0641FB1.jpg",
      "051B770A506161D514775D9914413C0598F3C1714EF88FF857E8B608854CE298.jpg",
      "9A18869336E88BA7AF056BD9E3AFB52640DDE704609A66F0ADCB627D1338307D.jpg",
      "E03A6E46B7915206A271038276989E6172FC32061A69C37E11AC2CDEB3E521C0.jpg",
      "7CE38E96C1415B1C55DA1018527E85D5A15D928C609F02627324DC85A7BFF839.jpg",
      "E971147C2AEA5BC0E30849EAC6C5152B7F50D6A846E0207D5AAC1377A954F67A.jpg",
      "101F915C39040303CC47D3283DAB9C69DC93C3BF6258F3FA578E672D5DAF070E.jpg",
      "386829DBCFD714CED09FF153BD36441C68C4AB06962BAD9DC8FCD9CC762D16B5.jpg",
      "3C8782886C972BAB825C575682FCD3D24148A49AFD40986F0BEB18CCD707FBFE.jpg",
      "0F8CE12071404ACC90A2FDBCA9788553876E92EE6B7121A51142B56EA1E1790E.jpg",
      "00012-458440370.jpeg",
      "1136497.png",
      "3978531179-1315543663.png",
    ]))
  }, [])

  return (
    <AnimatePresence>
      {/* dots */}

      {/* container needs to be here to prevent weird scrolling */}
      <div
        className={`${role === "assistant" ?  "items-end place-self-end" : "items-start"} w-fit max-w-xs`}
      >
        {isLoading && role === "assistant" ?
          <motion.div
            key="bubbles"
            className={`${role === "assistant" ? "bg-pink-400" : "bg-black/20"} px-3 py-1 rounded-md backdrop-blur-lg`}
            style={role === "assistant" ? {originX: 1, originY: 0} : {originX: 0, originY: 0}}
            initial={{scale: 0, opacity: 0}}
            animate={{scale: 1, opacity: 1}}
            exit={{scale: 0, opacity: 0}}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 24,
            }}
          >
            {/* typing indicator ellipsis */}
            <div className="flex h-8 items-center">
              {numToArray(3).map(i => {
                return (
                  <motion.div
                    key={i}
                    custom={i}
                    className="rounded-full bg-white"
                    // style={{margin: "0 4px"}}
                    variants={dotVariants}
                    initial={"rest"}
                    // animate={"bounce"}
                    animate={loop ? "bounce" : "rest"}
                    onAnimationComplete={(definition) => {
                        if (i === 0) {
                          if (definition === "bounce") {
                            setLoop(false)
                          } else {
                            setLoop(true)

                          }
                        }
                    }}

                  >
                  </motion.div>
                )
              })}
            </div>
          </motion.div>
        :
          // this is the message whereas before is typing indicators
          <motion.div
            key="message"
            className={`${role === "assistant" ? "bg-pink-400" : "bg-black/20"} rounded-md backdrop-blur-lg overflow-hidden`}
            style={role === "assistant" ? {originX: 1, originY: 1} : {originX: 0, originY: 1}}
            initial={{scale: 0, opacity: 0}}
            animate={{scale: 1, opacity: 1}}
            exit={{scale: 0, opacity: 0}}
            onAnimationComplete={() => {
              scrollToBottom()
            }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 24,
            }}
          >
            {image &&
              <img
                className="blur"
                src={`images/${randomImage}`}
              />
            }

            <div
              className={`px-3 py-1 text-left py-1 overflow-hidden`}
              // onClick={onClick}
            >
                {content}
            </div>
          </motion.div>
        }
      </div>
    </AnimatePresence>
  )
}



